import React from "react";
import "../../../pages/Home/Home.css";
import moment from "moment";
import {ROOMS_LOCATION} from "../../../RouteNames";

const initialPanes = [
  {
    key: "1",
    adults: 1,
    children: 0,
    childrenInfo: [],
    closable: false,
  },
];

export const IndosatPopularDestinations = (props) => {
  const {
    place,
    placeId=undefined,
    url,
    imageClass,
    flagImg = null,
  } = props
  const search = (event) => {
    event.preventDefault();

    // if( !localStorage.usertoken || !localStorage.userdata ) {
    //   console.log('props', props)
    //   props.showLogin();
    // }else{
      let start = new moment();
      let end = new moment(new Date().setDate(new Date().getDate() + 2));

      const placeName =  place.replaceAll(', ', '-').replaceAll(' ', '-');

      props.history.push({
        pathname: ROOMS_LOCATION.replace(':placeId', placeId).replace(':location', placeName),
        state: {
          place: place,
          placeId,
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD"),
          occupancies: [{ numOfAdults: 1 }],
          noofAdults: 1,
          noofChild: 0,
          noofroom: 1,
          panes: [initialPanes]
        },
      });
    // }
  };

  return (
    <div className="IndosatPopularDestination">
      <div
        className={imageClass}
        style={{ backgroundImage: `url( ${url} )` }}
        onClick={search}
      />
      <div className="top-left" onClick={search}>
        {place}
        {flagImg &&
          <div className="des-flag-img" style={{ backgroundImage: `url( ${flagImg} )` }} />
        }
      </div>
    </div>
  );
};
