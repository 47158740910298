import React, { useCallback, useRef, useState } from "react";


const DashboardSidebarBusiness = ({ isMobile, ...props }) => {

    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
    const isTabletScreen = !isDesktopScreen && window.matchMedia('(min-width: 576px)').matches;


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'start',
            flexDirection: 'column',
            width: '250px',
            position: 'relative',
            zIndex: 1,
            height: '100%',
            backgroundColor: '#fff',
            paddingLeft: '60px',
            paddingTop: '100px',
        }}>

            <a className="business-dashboard-link" href="/business-dashboard">Home</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Bookings</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Roaming</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Company</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Settings</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Team</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Payments</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Support</a>
            <a className="business-dashboard-link" href="javascript:void(0)">FAQ</a>
            <a className="business-dashboard-link" href="javascript:void(0)">Admin</a>
        </div>
    )
}

export default DashboardSidebarBusiness;
