import React from "react";
import SearchBar from "../../SearchBar/SearchBar";

const DashboardSearchBarBusiness = ({ hasProvider, setOpenLogin, history, openSignUp, }) => {
    return (
        <div style={{
            // width: '100vw',
            marginTop: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className="HeroText" style={{
                color: 'white',
                height: 'auto',
                marginBottom: '8rem',
                width: '100%',
            }}>
                <div style={{ marginTop: '100px', fontWeight: 'bolder', fontSize: '4rem', color: '#ffffff', opacity: 0.8, textAlign: 'center', }}>
                    Best Rooms. Low Rates <span style={{ color: '#d93b4f', }}>+</span> Free Roaming
                </div>
                <div style={{ fontWeight: 'bolder', fontSize: '4rem', color: '#ffffff', opacity: 0.8, textAlign: 'center', }}>
                    BOOK NOW
                </div>
            </div>
            <SearchBar hasProvider={hasProvider} showLogin={() => setOpenLogin(new Date())} history={history} />
            <div className="IndoSatHeroTextBottom" style={{ color: '#fff' }}>
                We have over 900,000 properties worldwide
            </div>
        </div>
    )
}

export default DashboardSearchBarBusiness;
