const data = [
  {
    name: "Hotel 1",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://pix6.agoda.net/hotelImages/426/4266747/4266747_18011815040061163418.jpg?s=312x",
  },
  {
    name: "Hotel Lorem Ipsum",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "Lorem ipsum dolor sit",
    imageurl:
      "https://i.travelapi.com/hotels/40000000/39460000/39453000/39452997/fa5628cf_b.jpg",
  },
  {
    name: "Hotel 3",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel 4",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel 1",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel Lorem Ipsum",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "Lorem ipsum dolor sit",
  },
  {
    name: "Hotel 3",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel 4",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel 1",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel Lorem Ipsum",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "Lorem ipsum dolor sit",
  },
  {
    name: "Hotel 3",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel 4",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel 1",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel Lorem Ipsum",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "Lorem ipsum dolor sit",
  },
  {
    name: "Hotel 3",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
  {
    name: "Hotel Last in list",
    checkindata: "Friday, 2020-11-21",
    checkoutdata: "Sunday, 2020-11-23",
    description: "",
    imageurl:
      "https://i.travelapi.com/hotels/20000000/19460000/19458500/19458493/2a72787e_z.jpg",
  },
];

const hotel = {
  name: "Teega Residence at Puteri Harbour",
  location: "Iskandar Puteri, Malaysia",
  stars: 5,
  checkindate: "Friday, 2020-11-30",
  checkintime: "12:00 PM",
  checkoutdate: "Saturday, 2020-12-01",
  checkouttime: "12:00 PM",
  bookingid: "3898392839823",
  status: "Completed",
  referenceid: "29382398",
  contactdetails: "bala.rao.12124124@gmail.com",
  guestname: "xyzadjfd",
  imageurl:
    "https://i.travelapi.com/hotels/2000000/1160000/1157500/1157448/0d3933eb_z.jpg",
  roomdetails: "Queen (x1)",
  paymentdetails: "Credit car prepayment - Total amount: MRY 2,399.00",
  details:
    "Set in a restored building, the hotel consists of 10 boutique-style rooms with free Wi-Fi. Located in the Penang capital of George Town, it is 3 km away from location attractions including the Goddess of Mercy Temple and Cheong Fatt Tze Mansion (Blue Mansion). Penang Airport is approximately 16 km away from the hotel. Cosy and charming, each air-conditioned room features classic teak furniture and local art work. A private bathroom with heated shower and TV with cable channels are included in the rooms. Rooms also have a seating area. Guests can enjoy a relaxing massage at Love Lane, which also provides.",
  RoomCount: 3,
  Guestcount: 4,
};
export default { data, hotel };
