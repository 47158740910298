import React, { Component } from "react";
import { Icon } from "antd";
import RoomPrice from "./RoomPrice";
import Facilities from "../Facilities";
import MediaQuery from "react-responsive";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import "./RoomPriceContainer.css";
import {_defaultHeaders, getCurrency, providerURL} from "../../AxiosHelper";
import Axios from "axios";

Array.prototype.hasMin = function(attrib) {
  return (this.length && this.reduce(function(prev, curr){
    return prev[attrib] < curr[attrib] ? prev : curr;
  })) || null;
}
export default class RoomPriceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : true,
      loadMore: true,
      modal: false,
      galleryStart: 0,
      roomsDisplayed: this.props.roomList.recomendation.length,
      recomendationData : []
    };

    if (this.props.roomList.recomendation.length === 0)
      this.props.itemFailedToLoad();
  }

  roomFailedToLoad = () => {
    this.setState((state) => ({
      roomsDisplayed: state.roomsDisplayed - 1,
    }));

    if (this.state.roomsDisplayed <= 0) this.props.itemFailedToLoad();
  };

  loadMore = () => {
    this.setState({
      loadMore: !this.state.loadMore,
    });
  };

  open = (index) => {
    document.body.style.overflow = "hidden";
    this.setState({
      galleryStart: index,
      modal: true,
    });
  };

  close = () => {
    document.body.style.overflow = "auto";
    this.setState({
      modal: false,
    });
  };

  loadRecomendation = async (recomenda) => {

    let currency = getCurrency();

    const headers = _defaultHeaders(
        currency,
        this.props.location.state.correlationId
    );

    const baseUrl = providerURL();

    const response = await Axios.get(
        `${baseUrl}/hotel/${this.props.hotelId}/${this.props.token}/price/recommendation/${recomenda}`,
        {
          headers: headers,
        }
    );

    return response;
  }

  processRecomendation = async (roomRecomandations, roomData) => {

    const recomendationData = {
      'refundable' : {
        breakfast : [],
        without : [],
      },
      'nonRefundable' : {
        breakfast : [],
        without : [],
      },
    };

    for (let i = 0; i < roomRecomandations.length; i++) {
      let response = await this.loadRecomendation(roomRecomandations[i].id)

      if (response.data.hotel && response.data.hotel.rates?.length > 0) {
        response.data.hotel.rates[0].totalRate = parseFloat(response.data.hotel.rates[0].totalRate).toFixed(2);
        const isRefundable = response.data.hotel.rates[0].refundability === 'Refundable'

        const key = isRefundable ? 'refundable' : 'nonRefundable';
        const hasBreakfast = response.data.hotel.rates[0].boardBasis.type === 'BedAndBreakfast' ? 'breakfast' : 'without';

        recomendationData[key][hasBreakfast].push({
          id : roomRecomandations[i].id,
          price : response.data.hotel.rates[0].totalRate,
          data: response.data, //TODO :: need to remove
          rates: response.data.hotel.rates,
          rooms: response.data.hotel.rooms,
        })
      }
    }

    recomendationData['refundable']['breakfast'] = recomendationData['refundable']['breakfast'].hasMin('price');
    recomendationData['refundable']['without'] = recomendationData['refundable']['without'].hasMin('price');
    recomendationData['nonRefundable']['breakfast'] = recomendationData['nonRefundable']['breakfast'].hasMin('price');
    recomendationData['nonRefundable']['without'] = recomendationData['nonRefundable']['without'].hasMin('price');


    const finalData = [];
    Object.values(recomendationData).forEach((data) => {
        if( data.breakfast ){
          finalData.push(data.breakfast)
        }
        if( data.without ){
          finalData.push(data.without)
        }
    });


    return finalData.sort((a, b) => a.price - b.price);;
  }

  loadAllRecomend = async () => {
    const roomData = this.props.roomList.roomDetails;

    // TODO :: NEED TO LOAD ALL RECOMENDED ID TO GROUP
    const roomRecomandations = this.props.roomList.recomendation;

    const responseRecomendation = await this.processRecomendation(roomRecomandations, roomData);

    // const responseRecomendation= roomRecomandations.map(async (reco) => {
    //   let response = await this.loadRecomendation(reco.id)
    //
    //   if (response.data.hotel && response.data.hotel.rates?.length > 0) {
    //     response.data.hotel.rates[0].totalRate = parseFloat(response.data.hotel.rates[0].totalRate).toFixed(2);
    //     const isRefundable = response.data.hotel.rates[0].refundability === 'Refundable'
    //
    //     const key = isRefundable ? 'refundable' : 'nonRefundable';
    //
    //     recomendationData[key].push({
    //       price : response.data.hotel.rates[0].totalRate,
    //       data: response.data, //TODO :: need to remove
    //       rates: response.data.hotel.rates,
    //       rooms: response.data.hotel.rooms,
    //     })
    //
    //     return {
    //       isRefundable,
    //       price : response.data.hotel.rates[0].totalRate,
    //       data: response.data, //TODO :: need to remove
    //       rates: response.data.hotel.rates,
    //       rooms: response.data.hotel.rooms,
    //     }
    //   }
    //
    //   return reco;
    // });

    // recomendationData.refundable.hasMin('price');
    // recomendationData.nonRefundable.hasMin('price');
    this.setState({
      loading: false,
      recomendationData : responseRecomendation
    })


  }

  componentDidMount = async () => {
    await this.loadAllRecomend();
  }

  render() {
    const roomData = this.props.roomList.roomDetails;
    const roomRecomandation = this.props.roomList.recomendation;
    const rates = this.props.roomList.rates;
    const room = this.props.roomList.room;
    const imagesArray = roomData.images?.map((image) => {
      return {
        original:
          image.links[1] && image.links[1].url
            ? image.links[1].url
            : image.links[0].url,
        thumbnail:
          image.links[1] && image.links[1].url
            ? image.links[1].url
            : image.links[0].url,
        description: image.caption,
      };
    }) ?? [];


    if( this.state.loading ){
      return <div style={{ display: "none" }} />;
    }

    if (this.state.roomsDisplayed <= 0)
      return <div style={{ display: "none" }} />;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          className="smallImagesModal"
          // style={customStyles}
        >
          <div className="smallImagesClose">
            <Icon
              type="close-circle"
              style={{ fontSize: "3rem", marginBottom: "1rem" }}
              onClick={this.close}
            />
          </div>
          <ImageGallery
            items={imagesArray}
            showFullscreenButton={false}
            showPlayButton={false}
            startIndex={this.state.galleryStart}
          />
        </Modal>

        <div
          className="combinationCostscontainer shadow"
          style={{
            border: "1px solid #e9edef",
            borderRadius: "10px",
          }}
        >
          <div className="combinationImageName">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="combinationCostscontainerroomName">
                {roomData.name}
              </div>

              {imagesArray.length > 0 && (
                <div className="RoomsImageContainer">
                  {imagesArray.map((element, index) => (
                    <div
                      key={index}
                      className="RoomsImage"
                      style={{
                        backgroundImage: `url( ${element.thumbnail} )`,
                        cursor: "pointer",
                      }}
                      onClick={() => this.open(index)}
                    />
                  ))}
                </div>
              )}

              <MediaQuery maxWidth={775}>
                {roomData.hasOwnProperty("facilities") &&
                  roomData.facilities.length > 0 && (
                    <Facilities number={4} facilities={roomData.facilities} />
                  )}
              </MediaQuery>
              <MediaQuery minWidth={776}>
                {roomData.hasOwnProperty("facilities") &&
                  roomData.facilities.length > 0 && (
                    <Facilities number={12} facilities={roomData.facilities} />
                  )}
              </MediaQuery>

              <div className="roomsPrices">
                {roomRecomandation.length > 0 &&
                  this.state.recomendationData.map((element,index) => (
                    <RoomPrice
                      hideElem={this.hide}
                      intialData={{
                        rates : rates[index],
                        room : room[index],
                      }}
                      location={this.props.location}
                      currency={this.props.roomList.currency}
                      hotelId={this.props.hotelId}
                      token={this.props.token}
                      config={this.props.config}
                      recommendationId={element?.id}
                      handleSelection={this.props.handleSelection}
                      selectedtoken={this.props.selectedtoken}
                      setPaymentData={this.props.setPaymentData}
                      roomFailedToLoad={this.roomFailedToLoad}
                      recommendationData={element}
                      key={element?.id}
                      openPolicyModal={this.props.openPolicyModal}
                      setModal={this.props.setModal}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
