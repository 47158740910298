import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import SearchBar from "../../components/SearchBar/SearchBar";
import "./Home.css";

import { PopularDestinations } from "./PopularDestinations";
import { NewsLetter } from "../../components/NewsLetter";

import des1 from "../../images/Home/des1.jpg"
import des2 from "../../images/Home/des2.jpg"
import des3 from "../../images/Home/des3.jpg"
import des4 from "../../images/Home/des4.jpg"
import des5 from "../../images/Home/des5.jpg"
import des6 from "../../images/Home/des6.jpg"
import des7 from "../../images/Home/des7.jpg"
import des8 from "../../images/Home/des8.jpg"
import des9 from "../../images/Home/des9.jpg"
import des10 from "../../images/Home/des10.jpg"

import yoodoBg from "../../images/provider/home/yoodo_bg.jpg"
import indoBg from "../../images/provider/home/indosat_bg_1.jpg"
import indosatLpHero from "../../images/Home/Indosat/indosat-lp-hero.svg"
import businessLpHero from "../../images/Home/Business/business-hero.png"
import businessLpDashboard from "../../images/Home/Business/business-dashboard.png"
import HomeContent from "../../components/Provider/Yoodo/HomeContent";
import HomeSearchBar from "../../components/Provider/Yoodo/HomeSearchBar";
import HomeContentIndo from "../../components/Provider/Indosat/HomeContentIndosat";
import HomeSearchBarIndo from "../../components/Provider/Indosat/HomeSearchBarIndosat";
import { IndosatNewsLetter } from "../../components/IndosatNewsLetter";
import HomeSearchBarYoodo from "../../components/Provider/Yoodo/HomeSearchBar";
import HomeContentYoodo from "../../components/Provider/Yoodo/HomeContent";
import { useCurrencyContext } from "../../useCurrencyContext";
import HomeContentBusiness from "../../components/Provider/Business/HomeContentBusiness";
import HomeSearchBarBusiness from "../../components/Provider/Business/HomeSearchBarBusiness";
import DashboardContentBusiness from "../../components/Provider/Business/DashboardContentBusiness";
import DashboardSearchBarBusiness from "../../components/Provider/Business/DashboardSearchBarBusiness";
import DashboardSidebarBusiness from "../../components/Provider/Business/DashboardSidebarBusiness";
import { BusinessDashboardNewsLetter } from "../../components/BusinessDashboardNewsLetter";

export const HomeDesktop = ({ hasProvider, provider, ...props }) => {
  // const [currency, setCurrency] = useState("MYR");

  const [selectedCurrency, setCurrency] = useCurrencyContext()
  const [openLogin, setOpenLogin] = useState(false);

  // useEffect(() => {
  //   setCurrency(localStorage.getItem("currency") || 'MYR');
  // }, []);


  const DefaultHomeContent = () => {
    return (
      <div>
        <div className="homeHeading">
          We have over 900,000 properties worldwide
        </div>
        <div className="Row">
          <PopularDestinations
            place="Kuala Lumpur, Malaysia"
            placeId={228226}
            currency={selectedCurrency}
            cost="9999"
            url={des1}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
          <PopularDestinations
            place="Singapore"
            placeId={246673}
            currency={selectedCurrency}
            cost="9999"
            url={des2}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
          <PopularDestinations
            place="Sydney, Australia"
            currency={selectedCurrency}
            placeId={383923}
            cost="9999"
            url={des3}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
          <PopularDestinations
            place="London, United Kingdom"
            placeId={435089}
            currency={selectedCurrency}
            cost="9999"
            url={des4}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
        </div>
        <div className="Row">
          <PopularDestinations
            place="New York, United States"
            currency={selectedCurrency}
            placeId={4349}
            cost="9999"
            url={des5}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="largeImage"
          />
          <PopularDestinations
            place="Qatar"
            currency={selectedCurrency}
            placeId={213428}
            cost="9999"
            url={des6}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="largeImage"
          />
        </div>
        <div className="Row">
          <PopularDestinations
            place="Paris, France"
            currency={selectedCurrency}
            cost="9999"
            placeId={665191}
            url={des7}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
          <PopularDestinations
            place="Taipei, Taiwan"
            currency={selectedCurrency}
            placeId={246729}
            cost="9999"
            url={des8}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
          <PopularDestinations
            place="Athens, Greece"
            currency={selectedCurrency}
            placeId={480712}
            cost="9999"
            url={des9}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
          <PopularDestinations
            place="Istanbul, Turkey"
            placeId={213435}
            currency={selectedCurrency}
            cost="9999"
            url={des10}
            showLogin={() => setOpenLogin(!openLogin)}
            history={props.history}
            imageClass="smallImage"
          />
        </div>
      </div>
    )
  }

  const DefaultHomeSearchBar = () => {

    const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

    return (
      <div className="HeroCenter">
        {/*<div className="HeroText">*/}
        {/*  Stay Connected <a href='/esim'><img alt={'esim'} style={{marginBottom:'10px', width:'70px'}} src={'esim-b.png'}/></a><br/>*/}
        {/*  Free mobile roaming in 121 countries*/}
        {/*</div>*/}
        <div className="HeroText" style={{
          color: 'white',
          height: 'auto',
          marginBottom: '9rem'
        }}>
          <div style={{ marginTop: 45, fontSize: '5rem', color: 'rgb(217, 59, 79)' }}>
            Free roaming with
          </div>
          <div style={{ fontSize: '5rem', color: 'rgb(217, 59, 79)' }}>
            every booking
          </div>
        </div>
        <SearchBar showLogin={() => setOpenLogin(new Date())} history={props.history} />
        {/* <div className="HeroTextBottom">
            We have over 900,000 properties worldwide + FREE data for roaming in 121 countries .
          </div> */}
      </div>
    )
  }

  const [modalType, setmodalType] = useState("Login");

  const openSignUp = () => {
    setmodalType("Register");
    setmodalVisible(true);
  };

  let pConfig = {
    name: null,
    textColor: '',
    searchBar: () => <DefaultHomeSearchBar />,
    content: () => <DefaultHomeContent />,
    newsLetter: () => <NewsLetter />
  };

  switch (provider) {
    case 'yoodo':
      pConfig.name = provider;
      pConfig.bCss = {
        backgroundColor: 'rgba(0,0,0,0.25)',
        backgroundImage: `url("${indoBg}")`,
        backgroundBlendMode: 'multiply',
        backgroundPositionY: '35%'
      }
      pConfig.textColor = "white";
      pConfig.searchBar = () => <HomeSearchBarYoodo setOpenLogin={setOpenLogin} history={props.history} />
      pConfig.content = () => <HomeContentYoodo setOpenLogin={setOpenLogin} currency={selectedCurrency} history={props.history} />
      pConfig.newsLetter = () => <IndosatNewsLetter />

      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    case 'indosat':
      pConfig.name = provider;
      pConfig.bCss = {
        // backgroundColor : '#FED80C',
        // backgroundImage: 'none',
        backgroundImage: `url("${indosatLpHero}")`,
        backgroundSize: 'cover',
        // backgroundColor : 'rgba(0,0,0,0.25)',
        // backgroundImage : `url("${indoBg}")`,
        backgroundBlendMode: 'multiply',
        // backgroundPositionY : '35%'
      }
      pConfig.textColor = "white";
      pConfig.searchBar = () => <HomeSearchBarIndo setOpenLogin={setOpenLogin} history={props.history} />
      pConfig.content = () => <HomeContentIndo setOpenLogin={setOpenLogin} currency={selectedCurrency} history={props.history} />
      pConfig.newsLetter = () => <IndosatNewsLetter />
      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    case 'business':
      pConfig.name = provider;
      pConfig.bCss = {
        // backgroundColor : '#FED80C',
        backgroundImage: `url("${businessLpHero}")`,
        backgroundSize: 'cover',
        // backgroundColor : 'rgba(0,0,0,0.25)',
        // backgroundImage : `url("${indoBg}")`,
        backgroundBlendMode: 'multiply',
        // backgroundPositionY : '35%'
        height: '100vh',
      }
      pConfig.textColor = "white";
      pConfig.searchBar = () => <HomeSearchBarBusiness openSignUp={openSignUp} setOpenLogin={setOpenLogin} history={props.history} />
      pConfig.content = () => <HomeContentBusiness setOpenLogin={setOpenLogin} currency={selectedCurrency} history={props.history} />
      pConfig.newsLetter = () => <IndosatNewsLetter />
      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    case 'business-dashboard':
      pConfig.name = provider;
      pConfig.bCss = {
        // backgroundColor : '#FED80C',
        backgroundImage: `url("${businessLpDashboard}")`,
        backgroundSize: 'cover',
        // backgroundColor : 'rgba(0,0,0,0.25)',
        // backgroundImage : `url("${indoBg}")`,
        backgroundBlendMode: 'multiply',
        // backgroundPositionY : '35%'
        height: '100vh',
        // marginBottom: '200px',
      }
      pConfig.textColor = "white";
      pConfig.searchBar = () => <DashboardSearchBarBusiness openSignUp={openSignUp} setOpenLogin={setOpenLogin} history={props.history} />
      pConfig.content = () => <DashboardContentBusiness setOpenLogin={setOpenLogin} currency={selectedCurrency} history={props.history} />
      pConfig.newsLetter = () => <BusinessDashboardNewsLetter />
      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    default:
      pConfig.name = null;
      pConfig.bCss = {
        backgroundColor: 'rgba(0,0,0,0.25)',
        backgroundImage: `url("${indoBg}")`,
        backgroundBlendMode: 'multiply',
        backgroundPositionY: '35%'
      }
      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
  }

  const [modalVisible, setmodalVisible] = useState(false);

  return (
    <div className="Home">
      <div className={`Hero ${provider === 'indosat' ? 'IndoSatHero' : ''}`} style={{
        ...pConfig.bCss,
        height: provider == 'business-dashboard' ? 'auto' : (provider == 'business' ? '100vh' : pConfig.height),
      }}>
        <Header
          logoColor={'white'}
          pConfig={pConfig}
          openLoginModal={openLogin}
          // containerStyle={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
          signupColor="white"
          openSignUp={openSignUp}
          modalVisible={modalVisible}
          setmodalVisible={setmodalVisible}
          modalType={modalType}
          setmodalType={setmodalType}
        />


        <div style={{
          display: provider !== 'business-dashboard' ? 'block' : 'none',
          height: '100%',
        }}>
          {hasProvider ? pConfig.searchBar() : <DefaultHomeSearchBar />}
        </div>
      </div>

      <div style={{
        ...pConfig.bCss,
        height: '100vh',
        // height: 'auto',
        backgroundImage: 'none',
        display: provider == 'business-dashboard' ? 'flex' : 'none',
        marginTop: '-95px',
        justifyContent: 'flex-start',
      }}>
        {provider == 'business-dashboard' ? <DashboardSidebarBusiness /> : <></>}

        <div style={{
          ...pConfig.bCss,
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: provider == 'business-dashboard' ? 'block' : 'none',
        }}>
          {hasProvider ? pConfig.searchBar() : <DefaultHomeSearchBar />}
        </div>
      </div>

      <div>
        {hasProvider ? pConfig.content() : <DefaultHomeContent />}
      </div>

      {hasProvider ? pConfig.newsLetter() : <NewsLetter />}

      <Footer />
    </div>
  );
};
