import React, { useState, useEffect } from "react";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import ForgotPassword from "./ForgotPassword";
import { Modal, Dropdown, Menu, Select, Avatar } from "antd";
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { Link, Redirect } from "react-router-dom";
import "./Header.css";
import LoginBg from '../../images/login_bg_1.png';
import { useCurrencyContext } from "../../useCurrencyContext";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'

const languages = [
  {
    country_code: 'gb',
    code: 'en',
    name: 'EN',
  },
  {
    country_code: 'id',
    code: 'id',
    name: 'ID',
  },
]

const Header = ({
  signupColor,
  containerStyle,
  openLoginModal = false,
  logoColor = null,
  isMobile = false,
  isDarkFont = false,
  // openSignUp,
  // modalVisible,
  // setmodalVisible,
  // modalType,
  // setmodalType,
}) => {

  const [modalType, setmodalType] = useState("Login")
  const [modalVisible, setmodalVisible] = useState(false)

  const { t } = useTranslation()
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = () => languages.find(language => language.code === currentLanguageCode)
  const [lang, setLang] = useState(currentLanguage)


  const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

  const [user, setuser] = useState(false);

  const [selectLang, setSelectedLang] = useState('EN');


  const [providerConfig, setProviderConfig] = useState({
    status: false,
    name: ''
  });
  // const [currency, setCurrency] = useState('MYR');
  const [selectedCurrency, setCurrency] = useCurrencyContext();
  const [actionMenuVisible, setActionMenuVisible] = useState(false);

  const changeCurrency = (val) => {
    localStorage.setItem("currency", val);
    setCurrency(val);
  };

  const openLogin = () => {
    setmodalType("Login");
    setmodalVisible(true);
  };
  const openSignUp = () => {
    setmodalType("Register");
    setmodalVisible(true);
  };

  const getDefaultCurrencyByProvider = () => {
    if (providerConfig && providerConfig.name === 'yoodo') {
      return 'MYR'
    }
    else {
      return 'USD'
    }
  }

  useEffect(() => {
    setCurrency(localStorage.getItem("currency") || 'MYR');
  }, []);

  useEffect(() => {
    if (localStorage.usertoken && localStorage.userdata) {

      const data = JSON.parse(localStorage.getItem("userdata"));

      setuser(data.first_name + " " + data.last_name);
    }
  }, []);

  useEffect(() => {
    if (openLoginModal) {
      openLogin();
    }

  }, [openLoginModal]);

  useEffect(() => {
    if (providerConfig?.name !== pConfig?.name) {
      let cfg = {
        status: false,
        name: null,
        loginMsgColor: null
      }

      if (hasProvider) {
        cfg = {
          status: true,
          name: pConfig?.name,
          loginMsgColor: pConfig?.textColor
        }
      }
      setProviderConfig({ ...providerConfig, ...cfg })
    }
  }, [hasProvider, pConfig]);

  useEffect(() => {
    if (!localStorage.getItem('currency')) {
      changeCurrency(getDefaultCurrencyByProvider());
    }
  }, [providerConfig])

  const signInMenu = (
    <Menu style={{ width: '12rem' }}>
      {/*<Menu.Item>*/}
      {/*  <Link to="/transactions">Your Transaction</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.Item>
        <div style={{ color: "#d93b4f", fontWeight: 'bold' }} onClick={openLogin}>
          LOG IN
        </div>
      </Menu.Item>
      <Menu.Item>
        <div style={{ color: "#d93b4f", fontWeight: 'bold' }} onClick={openSignUp}>
          SIGN UP
        </div>
      </Menu.Item>
    </Menu>
  )

  const actionMenu = (
    <Menu style={{ width: '12rem' }} >
      <Menu.Item key="1">
        <div className="header-currency-dropdown" style={{
          color: '#d93b4f', fontWeight: 'bold', marginRight: '10px',
          display: providerConfig.name == 'business' ? 'none' : 'block',
        }}>
          <Select
            value={selectedCurrency}
            onChange={(value) => { changeCurrency(value) }}
          >
            <Select.Option key="MYR">MYR</Select.Option>
            <Select.Option key="USD">USD</Select.Option>
            <Select.Option key="EUR">EUR</Select.Option>
            <Select.Option key="IDR">IDR</Select.Option>
            <Select.Option key="INR">INR</Select.Option>
            <Select.Option key="AED">AED</Select.Option>
            <Select.Option key="PHP">PHP</Select.Option>
            <Select.Option key="BHD">BHD</Select.Option>
            <Select.Option key="SAR">SAR</Select.Option>
            <Select.Option key="OMR">OMR</Select.Option>
            <Select.Option key="QAR">QAR</Select.Option>
          </Select>
        </div>
      </Menu.Item>
      {providerConfig.name === 'indosat' &&
        <Menu.Item key="2">
          {/* <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>
          <span onClick={() => setSelectedLang('ID')} style={{ color: selectLang === 'ID' ? '#d93b4f' : '#000', cursor: 'pointer' }}>ID</span>
          <span style={{ margin: '0px 5px' }}>|</span>
          <span onClick={() => setSelectedLang('EN')} style={{ color: selectLang === 'EN' ? '#d93b4f' : '#000', cursor: 'pointer' }}>EN</span>
          </div> */}

          <div>
            {languages && languages.map((language, index) => {
              return (
                <>
                  <span key={language.code}
                    onClick={() => {
                      i18next.changeLanguage(language.code)
                      setLang(language)
                    }}
                    className={lang.name == language.name ? 'text-primary': 'text-dark'}
                    style={{ cursor: 'pointer' }}>{language.name}</span>
                  {index !== languages.length - 1 ? <span style={{ margin: '0px 5px', fontWeight: 'bold', }}>|</span> : <></>}
                </>
              )
            })}
          </div>

        </Menu.Item>
      }
    </Menu>
  )

  const headerFontColor = isDarkFont ? '#000' : '#fff';

  const signInDesktop = (
    <div className="header-signin-container-wrapper">
      <div className="header-currency-dropdown" style={{
        color: '#d93b4f', fontWeight: 'bold', marginRight: '10px',
        display: providerConfig.name == 'business' ? 'none' : 'block',
      }}>
        <Select
          value={selectedCurrency}
          onChange={(value) => { changeCurrency(value) }}
        >
          <Select.Option key="MYR">MYR</Select.Option>
          <Select.Option key="USD">USD</Select.Option>
          <Select.Option key="EUR">EUR</Select.Option>
          <Select.Option key="IDR">IDR</Select.Option>
          <Select.Option key="INR">INR</Select.Option>
          <Select.Option key="AED">AED</Select.Option>
          <Select.Option key="PHP">PHP</Select.Option>
          <Select.Option key="BHD">BHD</Select.Option>
          <Select.Option key="SAR">SAR</Select.Option>
          <Select.Option key="OMR">OMR</Select.Option>
          <Select.Option key="QAR">QAR</Select.Option>
        </Select>
      </div>
      {/* {providerConfig.name === 'indosat' &&
        <div style={{ fontWeight: 'bold' }}><span onClick={() => setSelectedLang('ID')} style={{ color: selectLang === 'ID' ? '#d93b4f' : headerFontColor, cursor: 'pointer' }}>ID</span><span style={{ margin: '0px 5px' }}>|</span><span onClick={() => setSelectedLang('EN')} style={{ color: selectLang === 'EN' ? '#d93b4f' : headerFontColor, cursor: 'pointer' }}>EN</span></div>

        
      } */}

      <div>
        {providerConfig.name === 'indosat' &&
          <>
            {languages && languages.map((language, index) => {
              return (
                <>
                  <span key={language.code}
                    onClick={() => {
                      i18next.changeLanguage(language.code)
                      // currentLanguage()
                      setLang(language)
                    }}
                    className={lang.name == language.name ? 'text-primary': 'text-dark'}
                    style={{ cursor: 'pointer' }}>{language.name}</span>
                  {index !== languages.length - 1 ? <span style={{ margin: '0px 5px', fontWeight: 'bold', }}>|</span> : <></>}
                </>
              )
            })}
          </>
        }
      </div>

      <div className="buttonContainer" style={isMobile ? { marginTop: '-2rem' } : {}}>
        <div
          className="button"
          onClick={openSignUp}
          style={{ color: signupColor ? signupColor : "black", fontWeight: 'bold' }}
        >
          SIGN UP
        </div>
        <div className="button" style={{ color: "#d93b4f", fontWeight: 'bold' }} onClick={openLogin}>
          LOG IN 
        </div>
      </div>
    </div>
  )

  const signInMobile = (
    <div className="header-signin-container-wrapper">
      <div>
        <Dropdown trigger={['click']} overlay={signInMenu} placement="bottomLeft">
          <UserOutlined style={{ fontSize: '20px' }} />
        </Dropdown>
      </div>
      <div>
        <Dropdown trigger={['click']} overlay={actionMenu} placement="bottomLeft" visible={actionMenuVisible} onVisibleChange={(val) => setActionMenuVisible(val)}>
          <MenuOutlined style={{ fontSize: '20px' }} />
        </Dropdown>
      </div>
    </div>
  );

  // const defaultSignIn = (
  //   <div className="buttonContainer">
  //     <div
  //       className="button"
  //       onClick={openSignUp}
  //       style={{ color: signupColor ? signupColor : "black" }}
  //     >
  //       Sign Up
  //     </div>
  //     <div className="button" style={{ color: "#d93b4f" }} onClick={openLogin}>
  //       Log In
  //     </div>
  //   </div>
  // );

  const signIn = isMobile ? signInMobile : signInDesktop;

  const logout = (event) => {
    // event.preventDefault()
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userdata");
    localStorage.removeItem("useremail");
    localStorage.removeItem("is-test-acc");
    setuser(false);
    return <Redirect to="/" key={"random"} />;
  };

  const menu = (
    <Menu>
      {/*<Menu.Item>*/}
      {/*  <Link to="/transactions">Your Transaction</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.Item disabled>
        Hi, {user}
      </Menu.Item>
      <Menu.Item>
        <Link to="/Myaccount">My account</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/" onClick={logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const welcomeTextColor = isDarkFont ? '#000' : isMobile ? '#000' : '#fff';

  const welcomeMobile = (
    <div className="header-signin-container-wrapper">
      <div>
        <Dropdown trigger={['click']} overlay={menu} placement="bottomLeft">
          {/* <div style={{ cursor: "pointer", textWrap: 'nowrap', color: welcomeTextColor, display: 'flex', alignItems:'center'}}>
            Welcome, <b>{user}</b>
          </div> */}
          <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>{typeof user === 'string' ? user?.split(' ')?.map(e => e[0])?.join('') : 'U'}</Avatar>
        </Dropdown>
      </div>
      <div>
        <Dropdown trigger={['click']} overlay={actionMenu} placement="bottomLeft" visible={actionMenuVisible} onVisibleChange={(val) => setActionMenuVisible(val)}>
          <MenuOutlined style={{ fontSize: '20px' }} />
        </Dropdown>
      </div>
    </div>
  );

  const welcomeDesktop = (
    <div className="header-signin-container-wrapper">
      <div className="header-currency-dropdown" style={{
        color: '#d93b4f',
        fontWeight: 'bold',
        marginRight: '10px',
        display: providerConfig.name == 'business' ? 'none' : 'block',
      }}>
        <Select
          value={selectedCurrency}
          onChange={(value) => { changeCurrency(value) }}
        >
          <Select.Option key="MYR">MYR</Select.Option>
          <Select.Option key="USD">USD</Select.Option>
          <Select.Option key="EUR">EUR</Select.Option>
          <Select.Option key="IDR">IDR</Select.Option>
          <Select.Option key="INR">INR</Select.Option>
          <Select.Option key="AED">AED</Select.Option>
          <Select.Option key="PHP">PHP</Select.Option>
          <Select.Option key="BHD">BHD</Select.Option>
          <Select.Option key="SAR">SAR</Select.Option>
          <Select.Option key="OMR">OMR</Select.Option>
          <Select.Option key="QAR">QAR</Select.Option>
        </Select>
      </div>
      {providerConfig.name === 'indosat' &&
        <div style={{ fontWeight: 'bold' }}><span onClick={() => setSelectedLang('ID')} style={{ color: selectLang === 'ID' ? '#d93b4f' : headerFontColor, cursor: 'pointer' }}>ID</span><span style={{ margin: '0px 5px' }}>|</span><span onClick={() => setSelectedLang('EN')} style={{ color: selectLang === 'EN' ? '#d93b4f' : headerFontColor, cursor: 'pointer' }}>EN</span></div>
      }
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <div style={{ cursor: "pointer", textWrap: 'nowrap', color: welcomeTextColor, display: 'flex', alignItems: 'center' }}>
          Welcome,<b>{user}</b>
        </div>
      </Dropdown>
    </div>
  );


  const welcome = isMobile ? welcomeMobile : welcomeDesktop;

  return (
    <div className="Header" style={{
      ...containerStyle,
      position: 'relative',
      zIndex: 2,
      backgroundColor: providerConfig.status ? (providerConfig.name == 'business-dashboard' ? '#FED80C' : 'transparent') : 'transparent',
      paddingBottom: providerConfig.status ? (providerConfig.name == 'business-dashboard' ? '24px' : '0px') : '0px',
    }}>
      <Modal
        title={null}
        footer={null}
        visible={modalVisible}
        onCancel={() => setmodalVisible(false)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        bodyStyle={{
          backgroundImage: `url(${LoginBg})`,
          backgroundSize: 'cover',
          padding: '30px',
        }}
        className="loginModal"
      >
        <div style={{
          color: 'white',
          backgroundColor: '#ffffff30',
          backdropFilter: "blur(5px)",
          padding: 10,
        }}>
          {modalType !== "Forgot Password" && <p style={{ maxWidth: 350, textAlign: 'center' }}>{`Dear customer please ${modalType === "Register" ? "get registered" : "login"} to get members only special room rates`} </p>}
          {modalType === "Login" && (
            <LoginModal
              closeModal={() => setmodalVisible(false)}
              setmodalType={setmodalType}
              setuser={setuser}
              provider={providerConfig.status ? providerConfig.name : null}
            />
          )}
          {modalType === "Register" && (
            <SignUpModal
              closeModal={() => setmodalVisible(false)}
              setmodalType={setmodalType}
              setuser={setuser}
            />
          )}
          {modalType === "Forgot Password" && (
            <ForgotPassword closeModal={() => setmodalVisible(false)} />
          )}
        </div>
      </Modal>
      <div className="innercontainer"
        style={{
          // display: 'flex',
          // justifyContent: 'space-between',
          // alignItems: 'center',
        }}
      >
        <div style={{
          marginRight: "auto",
        }}>
          <Link to={providerConfig.status ? `/${providerConfig.name}` : '/'}>
            {providerConfig.status ? <div className={`logo-${providerConfig.name} ${logoColor ? logoColor : ''}`} /> : <div className="logo" />}
          </Link>
        </div>
        {/* {!isMobile && 
        <div>
          <span className="header-currency-dropdown" style={{ color: '#d93b4f', fontWeight: 'bold', marginRight: '10px' }}>
              <Select defaultValue="MYR">
                <Select.Option key="MYR">MYR</Select.Option>
                <Select.Option key="USD">USD</Select.Option>
                <Select.Option key="EUR">EUR</Select.Option>
                <Select.Option key="INR">INR</Select.Option>
                <Select.Option key="AED">AED</Select.Option>
                <Select.Option key="PHP">PHP</Select.Option>
                <Select.Option key="BHD">BHD</Select.Option>
                <Select.Option key="SAR">SAR</Select.Option>
                <Select.Option key="OMR">OMR</Select.Option>
                <Select.Option key="QAR">QAR</Select.Option>
                </Select></span>
          {providerConfig.name === 'indosat' &&       
          <span style={{ fontWeight: 'bold' }}><span  onClick={()=> setSelectedLang('ID')} style={{ color: selectLang === 'ID' ? '#d93b4f' : '#fff', cursor: 'pointer' }}>ID</span><span style={{ margin: '0px 5px'}}>|</span><span  onClick={()=> setSelectedLang('EN')} style={{ color: selectLang === 'EN' ? '#d93b4f' : '#fff', cursor: 'pointer' }}>EN</span></span>
          }
        </div>
        } */}
        {/* <h2>Welcome, <b>{user}</b></h2> */}

        <div style={{
          marginTop: providerConfig.status ? (providerConfig.name == 'business-dashboard' ? '24px' : '0px') : '0px',
        }}>
          {user ? welcome : signIn}
        </div>
      </div>
    </div>
  );
};

export default Header;
