import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagram, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { Select } from "antd";
import "../Footer.css";

import FooterLogo from "../../images/footer_logo.svg"
import FooterPayment from "../../images/Home/logo-full-final.svg"
import { useTranslation } from "react-i18next";

const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }
const { Option } = Select;
export const FooterMobile = ({ showEsim, ...props }) => {
  const {t} = useTranslation()
  return (
    <div className="FooterMobile">
      <div style={{
        width: '100%',
        display:['indosat', 'business', 'business-dashboard'].includes(pConfig?.name) ? 'block' : 'none'
        // display: window.location.pathname == '/business' || window.location.pathname == '/business-dashboard' ? 'none' : 'block',
      }}>
        <h2>{t('payment_partners')}</h2>

        {/* <div style={{
          backgroundColor: '#FFDE59',
          width: '100%',
        }}>
          <h3 style={{
            fontSize: '4rem',
            color: '#364F6F',
            textAlign: 'center',
          }}>MAKE YOUR <br />PAYMENT EASIER</h3>
        </div> */}

        <img src={FooterPayment} style={{
          width: '100%',
          marginTop: '2rem',
          marginBottom: '4rem',
        }} />
      </div>

      <div className="logo" />
      <div style={{ marginTop: '2rem' }}>
        <Link to="aboutus">About Us</Link>
      </div>
      <div>
        <Link to="contactus">Contact Us</Link>
      </div>
      <div>
        <Link to="/faq">Room FAQ</Link>
      </div>
      <div>
        <Link to="termsandconditions">Terms and Conditions</Link>
      </div>
      <div>
        <Link to="privacypolicy">Privacy Policy</Link>
      </div>

      <div style={{ borderBottom: '1px solid rgba(0,0,0,.1)', padding: '0.5rem' }}></div>

      {/* <div className="currency">
        <span>Currency</span>
        <div style={{marginLeft:'10px'}}>
          <Select
            value={props.currency}
            onChange={(value) => {
              props.changeCurrency(value);
            }}
          >
            <Option key="MYR">MYR</Option>
            <Option key="USD">USD</Option>
            <Option key="EUR">EUR</Option>
            <Option key="INR">INR</Option>
            <Option key="AED">AED</Option>
            <Option key="PHP">PHP</Option>
            <Option key="BHD">BHD</Option>
            <Option key="SAR">SAR</Option>
            <Option key="OMR">OMR</Option>
            <Option key="QAR">QAR</Option>
          </Select>
        </div>
      </div> */}
      <div>{t('follow_us')}</div>
      <div className="footersocial" style={{ gap: '5px' }}>
        {/*<a style={{color: 'green'}} target="_blank"  href="https://wa.link/wixktq">*/}
        {/*  <FaWhatsapp/>*/}
        {/*</a>*/}
        <a target="_blank" href="https://www.facebook.com/visitorsdeals">
          <FaFacebookSquare />
        </a>
        <a target="_blank" href="https://www.instagram.com/visitors_deals">
          <FaInstagram />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/visitorsdeals">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/visitorsdeals-travel/">
          <FaLinkedinIn />
        </a>
      </div>
      <img src={FooterLogo} alt="" style={{
        width: '100%'
      }} />
    </div>
  );
};
