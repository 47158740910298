import React from "react";
import "./LegalDocuments.css";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {

  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  const TranslatedPP = [
    {
      code: "one",
      content: true,
      contentArr: [],
    },
    {
      code: "two",
      content: true,
      contentArr: [],
    },
    {
      code: "three",
      content: true,
      contentArr: [],
    },
    {
      code: "four",
      content: true,
      contentArr: ['one', 'two', 'three'],
    },
    {
      code: "five",
      content: true,
      contentArr: [],
    },
    {
      code: "six",
      content: true,
      contentArr: [],
    },
    {
      code: "seven",
      content: true,
      contentArr: [],
    },
    {
      code: "eight",
      content: true,
      contentArr: [],
    },
    {
      code: "nine",
      content: true,
      contentArr: ['one', 'two', 'three'],
    },
    {
      code: "ten",
      content: true,
      contentArr: [],
    },
  ]
  const { t } = useTranslation()

  return (
    <div className="docsPage" style={{ height: "auto" }}>
      <Header isMobile={isMobile} isDarkFont={true} />
      <div className="docsContainer">
        <span className="docsHeading">{t('pp_title')}</span>
        <p className="docsPara">

          {TranslatedPP.map((pp, index) => (
            <div key={index}>
              <div className="docsSubHeading">{++index}. {t(`pp_${pp.code}_title`)}</div>
              {
                pp.content == true && (
                  <p className="docsParagraph">
                    {t(`pp_${pp.code}_content`)}
                  </p>
                )
              }

              {pp.contentArr.length > 0 && (
                <ol className="docsOrderedListAlphabet">
                  {pp.contentArr.map(c => (
                    <li>{t(`pp_${pp.code}_content_${c}`)}</li>
                  ))}
                </ol>
              )}
            </div>
          ))}

        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
