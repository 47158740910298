import { Button, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const IndosatNewsLetter = () => {

  const {t} = useTranslation()

  const [emailAddress, setEmailAddress] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubscribe = () => {
    setEmailAddress('');
    setShowSuccessMessage(true);
    setTimeout(()=>{
      setShowSuccessMessage(false);
    }, 5000)
  }

    return (
        <div className="indosat-news-letter-container">
        <div className="indosat-news-letter">
          <Input type="text" placeholder={t('enter_your_email')} value={emailAddress} onChange={(e)=> setEmailAddress(e.target.value)}></Input>
          <Button className="indosat-subscribe-btn" type="ghost" onClick={handleSubscribe}>Subscribe</Button>
        </div>
        {showSuccessMessage &&
        <p style={{ fontSize:'16px', color: 'green', fontWeight: 'bold', margin: '1rem'}}>Thanks for subscribing</p>
        }
        {/* <p style={{ fontSize:'16px', color: 'black', fontWeight: 'bold'}}>Sign Up for Exclusive Offers!</p> */}
        </div>
    );
  };