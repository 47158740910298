import React, { Component, useEffect } from "react";
import { Skeleton, Button, Tooltip } from "antd";
import Axios from "axios";
import "./RoomPrice.css";
import { _defaultHeaders, getCurrency, providerURL } from "../../AxiosHelper";
import { HiReceiptRefund } from "react-icons/hi";
import moment from "moment";
import { processCancellationPolicy } from "../../Helper";

const commas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

let currency = getCurrency();

export default class RoomPrice extends Component {
  constructor(props) {
    super(props);

    this.headers = _defaultHeaders(
      currency,
      props.location.state.correlationId
    );

    this.state = {
      currency: props.currency,
      loading: true,
      data: [],
      rates: props.intialData.rates,
      rooms: props.intialData.room,
      hide: false,
    };
  }

  handleKeyUp = (event) => {
    if (event.ctrlKey && event.keyCode === 75) {
      window.showProvider = true;
      const els = document.getElementsByClassName("recomendation-name")
      for (const el of els) {
        el.style.display = "block";
      }
    } else {
      if (window.showProvider) {
        window.showProvider = false
        const els = document.getElementsByClassName("recomendation-name")
        for (const el of els) {
          el.style.display = "none";
        }

      }
    }
  }

  componentDidMount = async () => {


    // setTimeout(() => {
    //   // Use 'self' instead of 'this' to refer to the outer context
    //   this.props.setModal(false)
    // }, 50z);

    document.addEventListener("keyup", this.handleKeyUp);

    if (this.props.recommendationId) {
      // let response;

      this.setState({
        data: {
          hotel: {
            id: '',
            rates: this.state.rates,
            rooms: [this.state.rooms]
          }
        }, //TODO :: need to remove
        rates: this.state.rates,
        rooms: this.state.rooms,
      });


      try {
        //
        // const baseUrl = providerURL();
        //
        // const response = await Axios.get(
        //   `${baseUrl}/hotel/${this.props.hotelId}/${this.props.token}/price/recommendation/${this.props.recommendationId}`,
        //   {
        //     headers: this.headers,
        //   }
        // );
        //
        // if (response.data.hotel && response.data.hotel.rates?.length > 0) {
        //
        //
        //   response.data.hotel.rates[0].totalRate = parseFloat(response.data.hotel.rates[0].totalRate).toFixed(2);
        //   this.setState({
        //     data: response.data, //TODO :: need to remove
        //     // rates: response.data.hotel.rates[0],
        //     rates: response.data.hotel.rates,
        //     // rooms: response.data.hotel.rooms[0],
        //     rooms: response.data.hotel.rooms,
        //   });
        // } else {
        //   this.setState({ hide: true });
        //   this.props.roomFailedToLoad();
        // }
        //
        this.setState(this.props.recommendationData ?? {})

      } catch (e) {
        this.setState({ rates: null });
        console.log("e - ", e);
      }
    }

    this.setState({ loading: false });
    // this.setState({ loading: false });
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);

  }

  onClick = async (totalRate, onlyPolicy = false) => {
    let response;
    this.setState({ priceCheckingLoading: true });

    // try {
    //   response = await Axios.get(
    //     `https://nexus.prod-env.vervotech.com/api/hotel/${this.props.hotelId}/${this.props.token}/price/recommendation/${this.props.recommendationId}`,
    //     {
    //       headers: this.headers,
    //     }
    //   );
    //
    //   if (response.data.hotel && response.data.hotel.rates?.length > 0) {
    //     response.data.hotel.rates[0].totalRate = parseFloat(response.data.hotel.rates[0].totalRate).toFixed(2);
    //     this.props.handleSelection(
    //         this.props.recommendationId,
    //         response.data.hotel.rates[0].totalRate,
    //         this.state.data.currency,
    //         response.data
    //     );
    //
    //   } else {
    //     this.setState({ hide: true });
    //     this.props.roomFailedToLoad();
    //   }
    // } catch (e) {
    //   this.setState({ rates: null });
    //   console.log("e - ", e);
    // }

    this.props.handleSelection(
      this.props.recommendationId,
      totalRate,
      // this.state.rates.totalRate,
      this.state.data.currency,
      this.state.data,
      false,
      // onlyPolicy,
      // bookingModalVisibility,
    );

    this.setState({ priceCheckingLoading: false });
  };


  render() {
    const rates = this.state.rates;
    const rooms = this.state.rooms;

    // let policyToolTip = "";
    // if (rates?.policies?.length) {
    //   policyToolTip = rates.policies.map((policy, index) => (
    //     <div key={index}>
    //       <div>{policy.type}:</div>
    //       <div>{policy.text}</div>
    //     </div>
    //   ));
    // }

    const refunToolTipFunc = (rates) => {

      let refundTooltip = "";

      rates.map(rate => {
        if (rate?.cancellationPolicies?.length) {
          const res = processCancellationPolicy(rate.cancellationPolicies, true);
          refundTooltip += <div> {res.text}</div>
        }
      })


      return refundTooltip;
    }

    const generateRoomDetail = (rooms) => {
      let roomName = '';
      rooms.forEach((room, index) => {
        const len = index + 1;
        roomName += room.name

        if (len < rooms.length)
          roomName += ' + '

      })

      return roomName.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });
    }

    const generatePrice = (rates) => {
      let rate = 0;
      rates.forEach(r => {
        rate += parseFloat(r.totalRate);
      })

      rate = Math.ceil(rate).toFixed(2)

      return (
        <div>
          <div style={{ height: "3vh" }}></div>
          <div className="roomSelect">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ color: "#858586" }}>Price</div>
              <div className="costRoom">
                {this.state.currency + " "}
                {commas(rate)}
              </div>
            </div>

            <Button type="primary" disabled={this.state.priceCheckingLoading} onClick={() => this.onClick(rate, false)}>
              {this.state.priceCheckingLoading ? "Final Price Checking..." : "Select"}
            </Button>
          </div>
          {/*{rates.map(rate => {*/}
          {/*  return (*/}
          {/*      <div className="roomSelect">*/}
          {/*        <div style={{ display: "flex", flexDirection: "column" }}>*/}
          {/*          <div style={{ color: "#858586" }}>Price</div>*/}
          {/*          <div className="costRoom">*/}
          {/*            {this.state.currency + " "}*/}
          {/*            {commas(rate.totalRate)}*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <Button type="primary" disabled={this.state.priceCheckingLoading} onClick={this.onClick}>*/}
          {/*          { this.state.priceCheckingLoading ? "Final Price Checking..." : "Select" }*/}
          {/*        </Button>*/}
          {/*      </div>*/}
          {/*  )*/}
          {/*})}*/}
        </div>
      )
    }

    const checkRefundablePolicy = (rates) => {
      let rate = 0;
      rates.forEach(r => {
        rate += parseFloat(r.totalRate);
      })

      rate = Math.ceil(rate).toFixed(2)

      // this.onClick(rate, false) // bookingModalVisibility = false
      // this.props.handleSelection(
      //   this.props.recommendationId,
      //   rate, // rate == totalRate
      //   // totalRate,
      //   // this.state.rates.totalRate,
      //   this.state.data.currency,
      //   this.state.data,
      //   false,
      // );

      this.props.handleSelection(
        this.props.recommendationId,
        rate,
        // totalRate,
        // this.state.rates.totalRate,
        this.state.data.currency,
        this.state.data,
        true,
        // onlyPolicy,
        // bookingModalVisibility,
      );


      // this.props.openPolicyModal()
    }

    if (!rates || !Array.isArray(rates) || !Array.isArray(rooms)) return <></>;

    const isRefundable = rates[0].refundability === 'Refundable'
    const supplier = rates[0].providerName ?? 'Unknown'

    const boardBasisGenerate = () => {
      let boardBasis = undefined;
      switch (rates[0].boardBasis.type) {
        case "BedAndBreakfast":
          boardBasis = 'Breakfast Included'
          break;
        case "Other":
        case "RoomOnly":
          boardBasis = undefined
          break;
        default:
          boardBasis = rates[0].boardBasis.description || rates[0].boardBasis.type
          break;

      }

      if (!boardBasis) return <></>

      return (
        <div className="blackroomType">
          {boardBasis}
        </div>
      )

    }

    return (
      <div className="RoomPriceContainer" key={this.props.recommendationId}>
        <span className="recomendation-name" style={{ display: "none" }}>
          Corelation : {this.props.location.state.correlationId}
        </span>
        <span className="recomendation-name" style={{ display: "none" }}>
          Recomendation : {this.props.recommendationId}
        </span>
        <div className="recomendation-name" style={{ display: "none", backgroundColor: 'red', color: 'white' }}>
          {supplier}
        </div>
        <div className="horizontal">
          {/*{rates.map(rate => {*/}

          {/*    return (*/}
          {/*        <div>*/}
          {/*          <div className="blackroomType">*/}
          {/*            {rate.boardBasis.description || rate.boardBasis.type}*/}
          {/*          </div>*/}

          {/*          {rate.refundable && <Tooltip title={refunToolTipFunc(rate)}>*/}
          {/*            <div className="blackroomType">*/}
          {/*              Refundable*/}
          {/*            </div>*/}
          {/*          </Tooltip>}*/}
          {/*        </div>*/}
          {/*    )*/}


          {/*})}*/}
          {boardBasisGenerate()}
          {isRefundable &&
            (<div onClick={() => checkRefundablePolicy(rates)} className="roomPriceIconBox">
                <div className="blackroomType">
                  Refundable
                </div>
                <svg  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M11 9h2V7h-2m1 13c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m-1 15h2v-6h-2z" /></svg>
            </div>
            )}

        </div>

        {Array.isArray(rooms) && generateRoomDetail(rooms)}
        {/*{rooms.name}*/}
        {this.state.loading && (
          <Skeleton paragraph={{ rows: 1, width: ["75%"] }} active />
        )}

        {!this.state.loading && generatePrice(rates)}
      </div>
    );
  }
}
