import React, { createContext, useContext, useState } from 'react';

// Create a context with an initial value
const CurrencyContext = createContext();

// Create a provider component to wrap your app with
export const CurrencyProvider = ({ children }) => {
  const defaultCurrency = localStorage.getItem("currency") || "MYR"
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  const setCurrency = (currency) => {
    setSelectedCurrency(currency);
  };

  // Pass the state and the setter function to the context provider
  return (
    <CurrencyContext.Provider value={[ selectedCurrency, setCurrency ]}>
      {children}
    </CurrencyContext.Provider>
  );
};

// Custom hook to consume the context
export const useCurrencyContext = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};
