import { Button, Icon, Input } from "antd";
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar/SearchBar";
import { MobileSkeleton } from "../../components/Skeleton/MobileSkeleton";
import Modal from "react-modal";
import "./SearchResults.css";

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    position: "absolute",
  },
};

const buttonStyle = {
  width: "100%",
  marginTop: "auto",
  alignSelf: "flex-end",
  marginBottom: "1.75rem",
};

const modalContainerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "calc(100vh - 40px)",
};

export const SearchResultsMobile = (props) => {
  const [modalOpen, setmodalOpen] = useState(false);
  const [filtersOpen, setfiltersOpen] = useState(false);
  const [sort, setsort] = useState(false);

  const { loading, hotelsLoading } = props;
  if (hotelsLoading) {
    return (
      <div className="SearchResultsMobile">
        <MobileSkeleton />
      </div>
    );
  }

  const { place, startDate, endDate, noofroom } = props.location;
  const { hotels, history, totalHotels } = props;
  const days = Math.round(
    (Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24)
  );

  const changeSearchTerm = (event) => {
    props.setCurrentPage(1)
    props.setHotelNameSearch(event.target.value)
  }

  document.body.style.overflow = "auto";
  return (
    <div className="SearchResultsMobile" style={{
      position: 'relative', zIndex: 2,
    }}>
      {/* Search Modal */}
      <Modal isOpen={modalOpen} style={customStyles}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Icon
            type="close-circle"
            style={{ fontSize: "3rem", marginBottom: "1rem" }}
            onClick={() => setmodalOpen(false)}
          />
        </div>
        <SearchBar
          roomInfoType="notModal"
          history={history}
          containerStyle={{ padding: "2rem 0" }}
          setContainingModal={setmodalOpen}
          place={props.location.place}
          startDate={props.location.startDate}
          endDate={props.location.endDate}
          panes={props.location.panes[0]}
          totalAdults={props.location.noofAdults}
          totalChildren={props.location.noofChild}
          totalRooms={props.location.noofroom}
        />
      </Modal>

      {/* Filters Modal */}
      <Modal isOpen={filtersOpen} style={customStyles}>
        <div style={modalContainerStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Icon
              type="close-circle"
              style={{ fontSize: "3rem", marginBottom: "1rem" }}
              onClick={() => setfiltersOpen(false)}
            />
          </div>

          <div style={{ marginTop: "1rem" }}>Price range</div>
          {props.slider}

          <div
            style={{
              // borderTop: "1px solid #E9EDEF",
              borderBottom: "1px solid #E9EDEF",
              padding: "1rem 0",
            }}
          >
            <div>Accommodation type</div>
            {props.AccommodationTypeFilters}
          </div>

          <div
            style={{
              borderBottom: "1px solid #E9EDEF",
              padding: "1rem 0",
              marginBottom: "2rem",
            }}
          >
            <div>Star ratings</div>
            {props.StarRatingFilters}
          </div>

          <Button
            type="primary"
            style={buttonStyle}
            onClick={() => setfiltersOpen(false)}
          >
            Search
          </Button>
        </div>
      </Modal>

      {/* Sort Modal */}
      <Modal isOpen={sort} style={customStyles}>
        <div style={modalContainerStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Icon
              type="close-circle"
              style={{ fontSize: "3rem", marginBottom: "1rem" }}
              onClick={() => setsort(false)}
            />
          </div>
          {props.sortBy}
          <Button
            type="primary"
            style={buttonStyle}
            onClick={() => setsort(false)}
          >
            Sort
          </Button>
        </div>
      </Modal>

      <div style={{ margin: "1.75rem" }}>
        <div className="resultsFor">
          <div className="searchHeading">
            {!hotelsLoading
              ? `${totalHotels} results found for ${place}`
              : `Looking for hotels at ${place}`}
          </div>
          <div span={4}>
            <Button type="link" onClick={() => setmodalOpen(true)}>
              Edit
            </Button>
          </div>
        </div>

        <div style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>
          Price for{" "}
          <b>
            {days}
            {days >= 2 ? " nights " : " night "}, {noofroom}
            {noofroom >= 2 ? " rooms " : " room "}{" "}
          </b>{" "}
          (tax and charges included)
        </div>

        <div style={{marginBottom:"10px"}}>
          <Input
              type="text"
              placeholder="Search Hotel Name"
              value={props.HotelNameSearch}
              onChange={changeSearchTerm}
              style={{ marginTop: "1rem" }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button style={{ width: "100%" }} onClick={() => setsort(true)}>
            Sort
          </Button>
          <Button
            style={{ width: "100%" }}
            onClick={() => setfiltersOpen(true)}
          >
            Filters
          </Button>
        </div>
      </div>

      <div className="SearchContainer">{hotels}</div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {props.paging}
      </div>
    </div>
  );
};
