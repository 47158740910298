import React, { Component } from "react";
import { Button, DatePicker, Select } from "antd";
import Modal from "react-modal";
import Autocomplete from "../autoCompleate/Autocomplete";
import "./SearchBar.css";
import RoomInfoMobile from "./RoomInfoMobile";
import moment from "moment";
import { useParams } from "react-router-dom";

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    position: "absolute",
  },
};

export default class SearchBarMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  setmodal = (modal) => this.setState({ modal });

  handleSearch = () => {
    if (this.props.containerStyle !== "modal") {
      this.props.setContainingModal && this.props.setContainingModal(false);
      this.props.search();
    }
  };

  
  render() {
    let roominfo;
    
    // const { location, placeId, rooms } = this.props.match.params;
    const isDisabled = ( !this.props.state.place) ? true : false
    // const isDisabled = (!location && !this.props.state.place && !this.props.state.activeSuggestion) ? true : false
    
    if (this.props.roomInfoType === "modal") {
      roominfo = (
        <Modal isOpen={this.state.modal} style={customStyles}>
          <RoomInfoMobile
            {...this.props.state}
            changeAdultCount={this.props.changeAdultCount}
            changeChildCount={this.props.changeChildCount}
            changeChildAge={this.props.changeChildAge}
            onChange={this.props.onChange}
            onEdit={this.props.onEdit}
            setmodal={this.setmodal}
            handleSearch={this.handleSearch}
            closable={true}
            showSaveButton={true}
          />
        </Modal>
      );
    } else {
      roominfo = (
        <RoomInfoMobile
          {...this.props.state}
          changeAdultCount={this.props.changeAdultCount}
          changeChildCount={this.props.changeChildCount}
          changeChildAge={this.props.changeChildAge}
          onChange={this.props.onChange}
          onEdit={this.props.onEdit}
          setmodal={this.setmodal}
          closable={false}
          showSaveButton={false}
        />
      );
    }
    return (
      <div className="MobileSearchBar" style={this.props.containerStyle}>
        <div className="inputContainer">
          <Autocomplete
            triggerParentLocation={this.props.setplace}
            triggerParentLocationClick={this.props.clickPlace}
            suggestionsStyle={{ width: "calc(100vw - 5.5rem)", zIndex: 100 }}
            inputStyle={{
              width: "calc(100vw - 5.5rem)",
              padding: "0",
              height: "32px",
              fontSize: "1.5rem",
            }}
            placeholder="Select Destination"
            value={this.props.state.place}
          />
        </div>

        <div className="inputContainer">
          <div className="mobileDatepicker">
            <DatePicker
              onChange={(date, dateString) =>
                this.props.changeDate(date, "start")
              }
              format="MMM DD"
              placeholder="Check In"
              value={
                this.props.state.startDate && moment(this.props.state.startDate)
              }
              style={{ marginRight: "1rem", padding: 0 }}
              disabledDate={(current) =>
                current && current < moment().subtract(1, "day").endOf("day")
              }
              allowClear={false}
              disabled={isDisabled}
            />
            to
            <DatePicker
              onChange={(date, dateString) =>
                this.props.changeDate(date, "end")
              }
              format="MMM DD"
              placeholder="Check Out"
              value={
                this.props.state.endDate && moment(this.props.state.endDate)
              }
              style={{ marginLeft: "1rem", padding: 0 }}
              disabledDate={(current) =>
                current && current < moment().subtract(1, "day").endOf("day")
              }
              allowClear={false}
              disabled={isDisabled}
            />
          </div>
        </div>

        <div
          // onClick={(e) => e.preventDefault()}
          onClick={() => this.setmodal(!this.state.modal)}
          className="RoomInfoContainer"
        >
          <div className="inputContainer">
            {/* <div className="inputDescription">Rooms</div> */}
            <Select
              className="selectInput"
              placeholder="Rooms"
              value={`${this.props.state.totalRooms} ${this.props.state.totalRooms > 1 ? "Rooms" : "Room"
                }`}
              open={false}
              disabled={isDisabled}
            />
          </div>

          <div className="inputContainer">
            {/* <div className="inputDescription">Adults</div> */}
            <Select
              className="selectInput"
              placeholder="Adults"
              onClick={(e) => e.preventDefault()}
              open={false}
              value={`${this.props.state.totalAdults} ${this.props.state.totalAdults > 1 ? "Adults" : "Adult"
                }`}
              disabled={isDisabled}
            />
          </div>

          <div className="inputContainer">
            {/* <div className="inputDescription">Children</div> */}
            <Select
              className="selectInput"
              placeholder="Children"
              onClick={(e) => e.preventDefault()}
              open={false}
              value={this.props.children(this.props.state.totalChildren)}
              disabled={isDisabled}
            />
          </div>
        </div>

        {roominfo}

        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={this.handleSearch}
        >
          Search
        </Button>
      </div>
    );
  }
}
