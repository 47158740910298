import React from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../SearchBar/SearchBar";

const HomeSearchBarIndosat = ({ hasProvider, setOpenLogin, history }) => {
  const { t } = useTranslation()
  return (
    <div className="HeroIndoSatCenter" style={{
      height: '100%',
      position: 'relative',
    }}>
      <div className="HeroText" style={{
        color: 'white',
        height: 'auto',
        marginBottom: '14rem',
      }}>
        <div style={{ marginTop: 45, fontSize: '5rem', color: 'rgb(217, 59, 79)' }}>
          {t('free_roaming_with')}
        </div>
        <div style={{ fontSize: '5rem', color: 'rgb(217, 59, 79)' }}>
          {t('every_booking')}
        </div>
        <div style={{ marginTop: 20, fontSize: '2rem', width: '40vw', color: 'black' }}>
          {t('book_your_rooms_and_enjoy')}
          <br></br>
          {t('malaysia_maldives_qatar')}
        </div>
      </div>

      <div style={{ position: 'absolute', bottom: '90px' }}>
        <SearchBar hasProvider={hasProvider} showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="IndoSatHeroTextBottom" style={{ color: '#000000', marginLeft: '-150px', }}>
          {t('we_have_900000_properties')}
        </div>
      </div>
    </div>
  )
}

export default HomeSearchBarIndosat;
