import { Button, Pagination } from "antd";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import responsedata from "./sampleBookingData";
import "./Booking.css";
import {Link, useHistory} from "react-router-dom";
import { SingleBooking } from "./SingleBooking";
import { IoIosArrowBack } from "react-icons/io";
import {getStorage} from "../../LocalStorageHelpers";
import {formatDate} from "../../Helper";

const BookingCard = ({ data, index, handleCustomerCare, handleClick }) => {
  let {
    name,
    checkindata,
    checkoutdata,
    imageurl, // not present in db
    paymentStatus, // not present in db
  } = data;

  const paymentStatusColor = {
    "PAID" : 'green',
    "UNPAID" : "red",
    "PENDING" : "yellow",
  }

  return (
    <div
      className="bookingCard"
      onClick={() => {
        window.scrollTo(0, 0);
        handleClick(index);
      }}
    >
      <div className="bookingCardInfo">
        <div className="subheading">{name}</div>

        <div className="checkindata">
          <div className="flexrow">
            <div className="faded">Check in: </div>
            <div>{formatDate(checkindata)}</div>
          </div>
          <div className="flexrow">
            <div className="faded">Check out: </div>
            <div>{formatDate(checkoutdata)}</div>
          </div>
          <div className="flexrow" style={{marginTop:"20px"}}>
            <div className="faded">Status: </div>
            <div style={{color:paymentStatusColor[paymentStatus]}}>{paymentStatus}</div>
          </div>
        </div>

        {/*<div className="faded">{description}</div>*/}
        {/*<div>*/}
        {/*  <Link*/}
        {/*    to="#"*/}
        {/*    style={{ color: "var(--vdOrange)" }}*/}
        {/*    onClick={(event) => handleCustomerCare(event)}*/}
        {/*  >*/}
        {/*    Chat with customer support*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </div>

      <div>
        <div
          className="bookingCardImage"
          style={{ backgroundImage: `url( ${imageurl} )` }}
        />
      </div>
    </div>
  );
};

export const AllBookings = ({navigation}) => {
  const [data, setdata] = useState([]);
  const [page, setpage] = useState(1);
  const [perpagecontent, setperpagecontent] = useState(8);
  const [hotelselected, sethotelselected] = useState(-1); // -1 == not selected

  const getData = async () => {
    const email= localStorage.getItem("useremail");

    let config = {
      method: "post",
      url: "/users/transactions",
      data: {
        email : email,
      },
      params: {
        // status: "PAID" 
      }
    };

    const transactionList = [];
    const res = await Axios(config)
    if( res.data.success ) {
      res.data.object.map((trx) => {
        transactionList.push({
          name : trx.hotel_name,
          checkindata : trx.checkin_date,
          checkoutdata: trx.checkout_date,
          imageurl: trx.hotel_image,
          paymentStatus: trx.status,
          bookingId: trx.id,
        })
      })

      setdata(transactionList);
    }

    // let { responsedata } = await Axios.get('/users/transactions/'+email);
  }

  const history = useHistory();

  useEffect(() => {
    // const email=localStorage.getItem("useremail");
    // let { responsedata } = await Axios.get('/users/transactions/'+email);

    // setdata(responsedata.data);
    getData()
  }, []);

  const handleCustomerCare = (event) => {
    event.stopPropagation();
    console.info("Hello Customer service here!");
    // TODO
  };

  const handleCardClick = async (cardIndex) => {
    console.info(document);
    sethotelselected(cardIndex);
    document.scrollingElement.scrollTop = 0;
  };

  const indexOfLastCard = page * perpagecontent;
  const indexOfFirstCard = indexOfLastCard - perpagecontent;
  let displayedData = data.slice(indexOfFirstCard, indexOfLastCard);

  const allbookings = (
    <div>
      <div className="heading">Booking</div>
      <div className="bookingCardContainer">
        {displayedData.map((booking, index) => (
          <BookingCard
            data={booking}
            key={index}
            index={index}
            handleCustomerCare={handleCustomerCare}
            handleClick={() => history.push(`/booking?refId=${booking.bookingId}`)}
          />
        ))}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        <Pagination
          onChange={(newpage, size) => {
            setpage(newpage);
            window.scrollTo(0, 0);
          }}
          current={page}
          defaultPageSize={perpagecontent}
          total={data.length}
        />
      </div>
    </div>
  );
  return (
    <div className="booking">
      {hotelselected === -1 ? (
        allbookings
      ) : (
        <>
          <Button
            type="link"
            style={{
              padding: "0",
              color: "var(--vdOrange)",
              marginBottom: "2rem",
            }}
            onClick={() => {
              sethotelselected(-1);
              document.body.scrollTop = 0;
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "max-content",
              }}
            >
              <IoIosArrowBack />
              All Bookings
            </div>
          </Button>

          <SingleBooking data={data[hotelselected]} />
        </>
      )}
    </div>
  );
};
