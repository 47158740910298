import React, { useCallback, useRef, useState } from "react";
import des1 from "../../../images/provider/indosat/des1.jpg"
import des2 from "../../../images/provider/indosat/des2.jpg"
import des3 from "../../../images/provider/indosat/des3.jpg"
import des4 from "../../../images/provider/indosat/des4.jpg"
import des5 from "../../../images/provider/indosat/des5.jpg"


const HomeContentBusiness = ({ isMobile, ...props }) => {
  const [showTncModal, setShowTncModal] = useState(false);

  const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
  const isTabletScreen = !isDesktopScreen && window.matchMedia('(min-width: 576px)').matches;

  const handlePrev = useCallback((swiperRef) => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback((swiperRef) => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="IndosatPopularDestionationsContainer" style={{
      columnGap: isDesktopScreen ? '10px' : 0,
    }}>


    </div>
  )
}

export default HomeContentBusiness;
