import React, { useCallback, useRef, useState } from "react";
import des1 from "../../../images/provider/indosat/des1.jpg"
import des2 from "../../../images/provider/indosat/des2.jpg"
import des3 from "../../../images/provider/indosat/des3.jpg"
import des4 from "../../../images/provider/indosat/des4.jpg"
import des5 from "../../../images/provider/indosat/des5.jpg"

import des1_flag from "../../../images/provider/indosat/des1_flag.jpg";
import des2_flag from "../../../images/provider/indosat/des2_flag.png";
import des3_flag from "../../../images/provider/indosat/des3_flag.jpg";
import des4_flag from "../../../images/provider/indosat/des4_flag.jpg";
import des5_flag from "../../../images/provider/indosat/des5_flag.jpg";

import tnc_img_icon from "../../../images/provider/indosat/tnc-icon.png";

import batuCaves from '../../../images/Home/Indosat/batu-caves.jpg'
import menaraKL from '../../../images/Home/Indosat/menara-kl.jpg'
import cameronHighlands from '../../../images/Home/Indosat/cameron-highlands.jpeg'
import tamanNegara from '../../../images/Home/Indosat/taman-negara.jpeg'
import zooNegara from '../../../images/Home/Indosat/zoo-negara.jpeg'
import dineInTheSky from '../../../images/Home/Indosat/dineintesky.jpeg'
import aquariaKLCC from '../../../images/Home/Indosat/aquaria-klcc.jpeg'
import petronas from '../../../images/Home/Indosat/petronas.webp'
import genting from '../../../images/Home/Indosat/genting.jpeg'
import theanHou from '../../../images/Home/Indosat/thean-hou.jpeg'
import sriMaha from '../../../images/Home/Indosat/sri-maha.jpeg'
import merdekaSquare from '../../../images/Home/Indosat/merdeka-square.jpeg'

import burJurmanCenter from '../../../images/Home/Indosat/bur-jurman-center.jpeg'
import dubaiMall from '../../../images/Home/Indosat/dubai-mall.jpeg'
import dubaiFest from '../../../images/Home/Indosat/dubai-fest-city.avif'
import dubaiMarinaMall from '../../../images/Home/Indosat/dubai-marina-mall.jpeg'
import cityWalk from '../../../images/Home/Indosat/city-walk.jpeg'
import emiratesMall from '../../../images/Home/Indosat/emirates-mall.jpeg'
import mercatoMall from '../../../images/Home/Indosat/mercato-mall.jpeg'
import ibnBattuta from '../../../images/Home/Indosat/ibn-battuta.jpeg'
import wafiMall from '../../../images/Home/Indosat/wafi-mall.jpeg'
import dubaiOutlet from '../../../images/Home/Indosat/Dubai-Outlet-Mall.jpg'


import cloudCafe from '../../../images/Home/Indosat/cloud-cafe.jpg'
import kitsune from '../../../images/Home/Indosat/kitsune.jpg'
import jypsy from '../../../images/Home/Indosat/jypsy.jpeg'
import kafeUtu from '../../../images/Home/Indosat/kafe-utu.jpeg'
import maxiCoffee from '../../../images/Home/Indosat/maxi-coffee.jpg'
import sernies from '../../../images/Home/Indosat/sernies.jpeg'
import freeRobot from '../../../images/Home/Indosat/free-robot.jpeg'
import awesomeCafe from '../../../images/Home/Indosat/awesome-cafe.jpeg'
import punch from '../../../images/Home/Indosat/punch.jpg'
import beardedBella from '../../../images/Home/Indosat/bearded-bella.jpg'
import butcherBoy from '../../../images/Home/Indosat/butcher-boy.jpeg'
import jaan from '../../../images/Home/Indosat/jaan.jpeg'
import tamarindHills from '../../../images/Home/Indosat/tamarind-hills.jpeg'
import koma from '../../../images/Home/Indosat/koma.jpeg'

import carouselItem1 from "../../../images/provider/indosat/carousel_1.jpg";
import carouselItem2 from "../../../images/provider/indosat/carousel_2.jpg";
import carouselItem3 from "../../../images/provider/indosat/carousel_3.jpg";

import { IndosatPopularDestinations } from "./IndosatPopularDestinations";
import { Carousel, Modal, Collapse } from "antd";
// import Swiper from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import indoBg from "../../../images/provider/home/indosat_bg_1.jpg"
import { useTranslation } from "react-i18next";

const HomeContentIndosat = ({ isMobile, ...props }) => {
  const { t } = useTranslation()

  const [showTncModal, setShowTncModal] = useState(false);

  const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
  const isTabletScreen = !isDesktopScreen && window.matchMedia('(min-width: 576px)').matches;

  const sliderOneRef = useRef(null);
  const sliderTwoRef = useRef(null);
  const sliderThreeRef = useRef(null);

  const handlePrev = useCallback((swiperRef) => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback((swiperRef) => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const reformatTranslatedString = (translatedString) => {
    // Remove more than 1 space
    translatedString = translatedString.replace(/\s{2,}/g, ' ');

    // Replace {{}} with <a href="the string inside {{}}"></a>
    translatedString = translatedString.replace(/\{\{([^}]+)\}\}/g, '<a href="$1">$1</a>');

    // Replace <br/> with \n
    translatedString = translatedString.replace(/<br\s*\/?>/ig, '\n');

    return translatedString;
  }


  return (
    <div className="IndosatPopularDestionationsContainer" style={{
      columnGap: isDesktopScreen ? '10px' : 0,
    }}>
      <div className="homeHeading" style={{ textAlign: 'center', marginBottom: isDesktopScreen ? '28px' : '0' }}>
        {t('discover_kl')}
      </div>
      <Swiper
        style={{
          width: '100%',
          // backgroundColor: 'grey'
        }}
        ref={sliderOneRef}
        // spaceBetween={20}
        // slidesPerView={6}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        className="indosat-swiper"
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
      >
        <button className="indosat-swiper-prev-arrow swiper-arrow prev" onClick={() => handlePrev(sliderOneRef)}></button>
        <button className="indosat-swiper-next-arrow swiper-arrow next" onClick={() => handleNext(sliderOneRef)}></button>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://kltower.com.my" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${menaraKL}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">KL Tower</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.malaysia.travel/explore/batu-caves" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${batuCaves}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Batu Caves</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.zoonegara.my/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${zooNegara}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Zoo Negara</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.dinnerinthesky.my/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${dineInTheSky}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Dinner In The Sky Malaysia</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://aquariaklcc.com/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${aquariaKLCC}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Aquaria KLCC</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.suriaklcc.com.my/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${petronas}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Petronas Twin Towers</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.rwgenting.com/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${genting}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Genting Highlands</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://trevo.my/stories/a-guide-to-thean-hou-temple-in-kuala-lumpur/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${theanHou}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Thean Hou Temple</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.google.com.pk/travel/hotels/entity/ChUIjfiWofuqj59vGgkvbS8wZ3czcnYQBA?utm_campaign=sharing&utm_medium=link&utm_source=htls&ved=0CAAQ5JsGahcKEwjYpqiYjpmFAxUAAAAAHQAAAAAQAw&ts=CAEaBAoCGgAqBAoAGgA" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${sriMaha}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Sri Maha Mariaman Temple</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a href="https://www.tejaonthehorizon.com/walkthrough/landmarks-of-dataran-merdeka/" target="_blank">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${merdekaSquare}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Merdeka Square</h5>
          </a>
        </SwiperSlide>
      </Swiper>

      <div className="homeHeading" style={{ textAlign: 'center', padding: 0, marginTop: '20px', marginBottom: isDesktopScreen ? '20px' : 0 }}>
        {t('free_roaming_destinations')}
      </div>
      {!isMobile && (
        <>
          <div className="first-row">
            <IndosatPopularDestinations
              place="Malaysia"
              currency={props.currency}
              placeId={228226}
              cost="9999"
              url={des1}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="largeImage"
            />
            <IndosatPopularDestinations
              place={t('singapore')}
              currency={props.currency}
              placeId={246673}
              cost="9999"
              url={des4}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="largeImage"

            />
          </div>
          <div className="second-row">
            <IndosatPopularDestinations
              place="Qatar"
              currency={props.currency}
              placeId={213428}
              cost="9999"
              url={des3}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="smallImage"
            />
            <IndosatPopularDestinations
              place="Maldives"
              currency={props.currency}
              placeId={227609}
              cost="9999"
              url={des2}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="smallImage"
            />
            <IndosatPopularDestinations
              place="UAE"
              currency={props.currency}
              placeId={221688}
              cost="9999"
              url={des5}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="smallImage"
            />
          </div>
        </>
      )
      }
      {isMobile &&
        (<Carousel className="indosat-img-carousel" autoplay={true} dotsClass="carouselContainerDots" dots={true}>
          <IndosatPopularDestinations
            place="Malaysia"
            currency={props.currency}
            placeId={228226}
            cost="9999"
            url={des1}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <IndosatPopularDestinations
            place="Singapore"
            currency={props.currency}
            placeId={246673}
            cost="9999"
            url={des4}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <IndosatPopularDestinations
            place="Qatar"
            currency={props.currency}
            placeId={213428}
            cost="9999"
            url={des3}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <IndosatPopularDestinations
            place="Maldives"
            currency={props.currency}
            placeId={227609}
            cost="9999"
            url={des2}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <IndosatPopularDestinations
            place="UAE"
            currency={props.currency}
            placeId={221688}
            cost="9999"
            url={des5}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
        </Carousel>
        )
      }
      <div className="tnc-row">
        <div className="tnc-img-container" style={{ backgroundImage: `url( ${tnc_img_icon} )` }} />
        <div className="tnc-title-container">
          {isMobile ?
            (
              <div className="tnc-title" style={{ textAlign: 'center' }}>{t('free_roaming_tnc')}</div>
            ) :
            (
              <div className="tnc-title">
                {t('free_roaming_tnc')}
              </div>
            )
          }
        </div>
        <div className="tnc-open-modal-btn">
          <button onClick={() => setShowTncModal(true)}>See Details</button>
        </div>
      </div>

      <div className="homeHeading" style={{ textAlign: 'center', marginBottom: isDesktopScreen ? '28px' : '12px' }}>
        {t('explore_dubai')}
      </div>

      <Swiper
        style={{
          width: '100%',
          // backgroundColor: 'grey'
        }}
        ref={sliderTwoRef}
        spaceBetween={20}
        slidesPerView={6}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        className="indosat-swiper"
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
      >
        <button className="indosat-swiper-prev-arrow swiper-arrow prev" onClick={() => handlePrev(sliderTwoRef)}></button>
        <button className="indosat-swiper-next-arrow swiper-arrow next" onClick={() => handleNext(sliderTwoRef)}></button>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.visitdubai.com/en/places-to-visit/dubai-mall">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${dubaiMall}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Dubai Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.citywalk.ae/en">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${cityWalk}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">City Walk</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.dubaifestivalcitymall.com/home">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${dubaiFest}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Dubai Festival City Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.malloftheemirates.com/en">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${emiratesMall}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Mall of The Emirates</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.mercatoshoppingmall.com/">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${mercatoMall}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Mercato Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.ibnbattutamall.com/en">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${ibnBattuta}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Ibn Battuta Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.wafi.com/">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${wafiMall}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">WAFI Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.dubaimarinamall.com/">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${dubaiMarinaMall}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Dubai Marina Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://dubaioutletmall.com/">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${dubaiOutlet}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">Dubai Outlet Mall</h5>
          </a>
        </SwiperSlide>
        <SwiperSlide className="indosat-swiper-slide">
          <a target="_blank" href="https://www.burjuman.com">
            <div className="indosat-swiper-slide-thumbnail"
              style={{
                backgroundImage: `url("${burJurmanCenter}")`,
              }}
            ></div>
            <h5 className="indosat-swiper-slide-title">BurJuman</h5>
          </a>
        </SwiperSlide>
      </Swiper>

      <div className="indosat-img-carousel">
        <Carousel autoplay={true} dotsClass="carouselContainerDots" dots={true} >
          <div className="indosat-carousel-item">
            <div className="carousel-img" style={{ backgroundImage: `url( ${carouselItem1} )` }}></div>
            <div className="centered-overlay-title">
              <div className="overlay-title-text">Upgrade to Luxury Suite</div>
              <div className="overlay-title-link" onClick={() => setShowTncModal(true)}>See Details</div>
            </div>
          </div>
          <div className="indosat-carousel-item">
            <div className="carousel-img" style={{ backgroundImage: `url( ${carouselItem2} )` }}></div>
            <div className="centered-overlay-title">
              <div className="overlay-title-text">Win dinner voucher</div>
              <div className="overlay-title-link" onClick={() => setShowTncModal(true)}>See Details</div>
            </div>
          </div>
          <div className="indosat-carousel-item">
            <div className="carousel-img" style={{ backgroundImage: `url( ${carouselItem3} )` }}></div>
            <div className="centered-overlay-title">
              <div className="overlay-title-text">Win KLCC Tour</div>
              <div className="overlay-title-link" onClick={() => setShowTncModal(true)}>See Details</div>
            </div>
          </div>
        </Carousel>
        <Modal
          title="Terms & Conditions"
          visible={showTncModal}
          onOk={() => setShowTncModal(false)}
          onCancel={() => setShowTncModal(false)}
          footer={null}
          style={{ top: 10 }}
          className="tnc-modal"
        >
          <div style={{ padding: '0 50px', }}>
            <ol>
              <li>{reformatTranslatedString(t('tnc_two'))}</li>
              <li>{reformatTranslatedString(t('tnc_three'))}</li>
              <li>{reformatTranslatedString(t('tnc_four'))}</li>
              <div dangerouslySetInnerHTML={{ __html: t('tnc_four') }}></div>
              <ol type="i" style={{marginLeft: '50px'}}>
                <li>{reformatTranslatedString(t('tnc_four_subone'))}</li>
                <li>{reformatTranslatedString(t('tnc_four_subtwo'))}</li>
                <li>{reformatTranslatedString(t('tnc_four_subthree'))}</li>
                <li>{reformatTranslatedString(t('tnc_four_subfour'))}</li>
                <li>{reformatTranslatedString(t('tnc_four_subfive'))}</li>
              </ol>
              <li>{reformatTranslatedString(t('tnc_five'))}</li>
              <li>{reformatTranslatedString(t('tnc_six'))}</li>
              <li>{reformatTranslatedString(t('tnc_seven'))}</li>
              <li>{reformatTranslatedString(t('tnc_eight'))}</li>
              <li>{reformatTranslatedString(t('tnc_nine'))}</li>
              <div dangerouslySetInnerHTML={{ __html: t('tnc_ten') }}></div>
              <li>{reformatTranslatedString(t('tnc_eleven'))}</li>
              <li>{reformatTranslatedString(t('tnc_twelve'))}</li>
              <li>{reformatTranslatedString(t('tnc_thirteen'))}</li>
              <div dangerouslySetInnerHTML={{ __html: t('tnc_fourteen') }}></div>
              {/* <Collapse.Panel header="General Terms and Conditions" key="1">
              <ul style={{ margin: '0 10px' }}>
                <li>
                  The rates listed include 10% VAT, valid for all prepaid and postpaid Indosat Ooredoo Hutchison customers.
                </li>
                <li>
                  Benefits Unlimited Internet & Blackberry is valid for all operators in the destination country (subject to GPRS/3G/LTE service availability).
                </li>
                <li>
                  The above roaming rates do not apply to maritime and satellite operator networks.
                </li>
                <li>
                  Tariffs can change at any time without any prior notification from Indosat Ooredoo Hutchison.
                </li>
                <li>
                  The availability and quality of roaming services (voice calls, SMS, internet, and BlackBerry) in the destination country depends on the coverage, compatibility, and network quality of Indosat Ooredoo Hutchison's roaming partner operator.
                </li>
              </ul>
            </Collapse.Panel>
            <Collapse.Panel header="Terms of Use Unlimited Roaming Package Benefits" key="2">
              <ul style={{ margin: '0 10px' }}>
                <li>
                  Please ensure that the Data Roaming feature on your device is turned on. For example, on an Android device : Settings -{">"} More -{">"} Mobile Networks -{">"} Data roaming, change the option to Enable/ON to enable.
                </li>
                <li>
                  The APN settings used on the customer's device are: indosatgprs.
                </li>
                <li>
                  You do not need to manually select the operator in the roaming destination country.
                </li>
                <li>
                  BlackBerry (BB) customers with versions below OS10 must have activated the BB package before using roaming services. The BB feature when roaming will follow the BB feature that customers register before traveling abroad. Example: If a customer while in Indonesia has registered for the BB Gaul package, when roaming, the registered package will automatically be carried over when the customer is roaming abroad.
                </li>
                <li>
                  Roaming Data Control is a service that allows postpaid subscribers to automatically monitor data or Internet service usage while abroad. The customer will receive an SMS containing information on roaming data usage when it is still below the normal usage limit and data usage will be blocked when it is outside the normal usage limit. While telephone service & SMS can still be used.
                </li>
                <li>
                  The package's active period and time zone refers to the time in Indonesia (WIB).
                </li>
              </ul>
            </Collapse.Panel> */}
            </ol>
          </div>
        </Modal>

        <div className="homeHeading" style={{ textAlign: 'center', marginTop: '70px', marginBottom: isDesktopScreen ? '28px' : '12px' }}>
          {t('cafes_experience')}
        </div>

        <Swiper
          style={{
            width: '100%',
            // backgroundColor: 'grey'
          }}
          ref={sliderThreeRef}
          spaceBetween={20}
          slidesPerView={6}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
          className="indosat-swiper"
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
        >
          <button className="indosat-swiper-prev-arrow swiper-arrow prev" onClick={() => handlePrev(sliderThreeRef)}></button>
          <button className="indosat-swiper-next-arrow swiper-arrow next" onClick={() => handleNext(sliderThreeRef)}></button>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://www.instagram.com/cloud._.co/?hl=en" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${cloudCafe}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Cloud Café</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://maisonkitsune.com/mk/find-a-store/cafe-kitsune-capitol-2/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${kitsune}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Kitsune</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://www.pscafe.com/jypsy-one-fullerton" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${jypsy}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Jypsy</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://kafeutu.com/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${kafeUtu}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Kafe Utu</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://maxicoffeebar.com/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${maxiCoffee}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Maxi Coffee Bar</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://sarnies.com/Singapore" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${sernies}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Sarnies</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://www.freetherobotcoffee.com/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${freeRobot}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Free The Robot</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://www.myawesomecafe.com/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${awesomeCafe}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">My Awesome Café</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://punch.sg/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${punch}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Punch</h5>
            </a>
          </SwiperSlide>
          <SwiperSlide className="indosat-swiper-slide">
            <a href="https://www.beardedbella.com/" target="_blank">
              <div className="indosat-swiper-slide-thumbnail"
                style={{
                  backgroundImage: `url("${beardedBella}")`,
                }}
              ></div>
              <h5 className="indosat-swiper-slide-title">Bearded Bella</h5>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default HomeContentIndosat;
