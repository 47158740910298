import React from "react";
import "antd/dist/antd.css";
import "./LoginModal.css";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import Axios from "axios";
import { MDBAlert } from "mdbreact";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from "react-google-login";

class NormalLoginForm extends React.Component {

  state = {
    errors: null
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ errors: null })

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let response = await Axios.post("users/login", values);

        if (response.data === "Error: Wrong Email/Password") {
          this.setState({ errors: response.data });
        } else if (response.data) {

          const name = response.data.userObject.first_name + " " + response.data.userObject.last_name;

          this.props.setuser(name);
          localStorage.setItem("usertoken", response.data.token);
          localStorage.setItem("userdata", JSON.stringify({
            first_name: response.data.userObject.first_name,
            last_name: response.data.userObject.last_name,
            contactNumber: response.data.userObject.contactNumber,
          }));
          localStorage.setItem("useremail", response.data.userObject.email);

          if (response.data.userObject.email === 'test_account@visitorsdeals.com') {
            localStorage.setItem("is-test-acc", true);
          } else {
            localStorage.setItem("is-test-acc", false);
          }

          this.props.closeModal();

          if (this.props.provider == 'business') {
            setTimeout(() => {
              window.location.href = "/business-dashboard"
            }, 1000);
          }
        }
      }
    });
  };

  responseGoogle = async (response) => {
    console.log(response.profileObj);

    let values = response.profileObj
    if (values) {
      values.type = 'google';
      try {
        let response = await Axios.post("users/social", values);
        if (response.data) {
          this.props.setuser(response.data.userObject.name);
          localStorage.setItem("usertoken", response.data.token);
          localStorage.setItem("userdata", JSON.stringify({
            first_name: response.data.userObject.first_name,
            last_name: response.data.userObject.last_name,
            contactNumber: response.data.userObject.contactNumber,
          }));
          localStorage.setItem("useremail", response.data.userObject.email);
          this.props.closeModal();
        }
      } catch (error) {
        this.setState({ error: "Error in login" });
        console.log(error);
      }
    } else {
      this.setState({ error: "Error in login" });
    }
  };

  responseFacebook = async (response) => {
    console.log(response);

    let values = {
      email: response.email,
      givenName: response.name,
      familyName: '',
      type: 'facebook',
      socialId: response.userID
    }

    try {
      let response = await Axios.post("users/social", values);
      if (response.data) {
        this.props.setuser(response.data.userObject.name);
        localStorage.setItem("usertoken", response.data.token);
        localStorage.setItem("userdata", JSON.stringify({
          first_name: response.data.userObject.first_name,
          last_name: response.data.userObject.last_name,
          contactNumber: response.data.userObject.contactNumber,
        }));
        localStorage.setItem("useremail", response.data.userObject.email);
        this.props.closeModal();
      }
    } catch (error) {
      this.setState({ error: "Error in Signup" });
      console.log(error);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          className="login-form"
          hideRequiredMark={true}
          colon={false}
        >
          {this.state.errors !== null && (
            <MDBAlert color="danger">{this.state.errors}</MDBAlert>
          )}

          <FacebookLogin
            appId="208307229975285"
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                style={{
                  backgroundColor: "#405a93",
                  color: "white",
                  width: "100%",
                  padding: "1rem 0",
                  height: "auto",
                  borderRadius: "3px",
                  marginBottom: "1rem",
                }}
              >
                Continue with Facebook
              </Button>
            )}
          />

          <GoogleLogin
            className="loginBtn loginBtn--google"
            clientId="255531455220-0gu1fj71v1jr783csupghm5gcjqq4uc6.apps.googleusercontent.com"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={"single_host_origin"}
            buttonText=" Login with Google"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                style={{
                  backgroundColor: "#cf5b4d",
                  color: "white",
                  width: "100%",
                  padding: "1rem 0",
                  height: "auto",
                  borderRadius: "1px",
                  marginBottom: "1rem",
                }}
              >
                Continue with Google
              </Button>
            )}
          />

          <Form.Item label={<span style={{ color: 'white' }}>Email</span>}>
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "string",
                  message: "Please input a valid email",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={<span style={{ color: 'white' }}>Password</span>}>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your Password!" }],
            })(<Input type="password" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("remember", {
              valuePropName: "checked",
              initialValue: true,
            })(<Checkbox style={{ color: 'white' }}>Remember me</Checkbox>)}
            {/* <a className="login-form-forgot" onClick={() => this.props.setmodalType("Forgot Password")}>
            Forgot password
          </a> */}
            <Button
              style={{ float: "right", color: 'white', paddingTop: 10, fontSize: 12 }}
              type="link"
              onClick={() => this.props.setmodalType("Forgot Password")}
            >
              <span style={{ textDecoration: 'underline' }}>Forgot password</span>
            </Button>
            <div style={{ width: '100%' }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "1rem", width: "100%" }}
                onClick={this.handleSubmit}
              >
                Log in
              </Button>
              {/*<Button type="default" onClick={this.props.closeModal}>*/}
              {/*  Cancel*/}
              {/*</Button>*/}
            </div>
            <span style={{ color: 'white', fontSize: 14 }}>Dont have an account?</span>
            {/* <a onClick={() => this.props.setmodalType("Register")} >Create one</a> */}
            <Button
              style={{ paddingLeft: '5px', color: 'white' }}
              type="link"
              onClick={() => this.props.setmodalType("Register")}
            >
              <b>Create one</b>
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const LoginModal = Form.create({ name: "normal_login" })(NormalLoginForm);
export default LoginModal;
