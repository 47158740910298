import React, { useState } from "react";
import { Icon, Rate } from "antd";
import { HotelDescription } from "../../components/HotelDescription";
import { Link } from "react-router-dom";
import Landmarks from "../../components/MobileRoom/Landmarks";
import HotelLocation from "../../components/MobileRoom/HotelLocation";
import RoomsAndRates from "../../components/HotelDesktop/RoomsAndRates";
import FooterPage from "../../components/Footer";
import ImageGallery from "react-image-gallery";
import moment from "moment";

const ShowMore = ({ state, setState }) => (
  <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      setState(!state);
    }}
    style={{ color: "#F48247" }}
  >
    {state ? "View less" : "Show more"}
  </Link>
);

export const AccommodationDetailsMobile = (props) => {
  const [about, setabout] = useState(false);
  const [viewBookingSummary, setviewBookingSummary] = useState(false);
  const { data } = props;
  const { images, name, contact, starRating } = data;

  const days = Math.round(
    (Date.parse(props.location.state.endDate) -
      Date.parse(props.location.state.startDate)) /
      (1000 * 60 * 60 * 24)
  );

  const imagesArray = images.map((image) => {
    return {
      original:
        image.links[1] && image.links[1].url
          ? image.links[1].url
          : image.links[0].url,
      thumbnail: image.links[0].url,
      description: image.caption,
    };
  });

  return (
    <div className="AccommodationDetailsMobile">
      {props.header}

      {props.bookingModal}
      {props.policyModal()}

      <div style={{ marginTop:'40px' }}>
        <ImageGallery
            items={imagesArray}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
            showNav={true}
            disableSwipe={false}
            showBullets={false}
        />
      </div>

      <div className="information">
        <div className="mainHeading">{name}</div>
        <div className="grayInfo">
          {contact.address.city.name}, {contact.address.country.name}
        </div>
        <div>
          <div className="roomInfo">
            <div className="SearchResultsRoomType">{data.type}</div>
            <Rate
              disabled={true}
              value={Number(starRating)}
              className="Searchstars"
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <div className="subHeading">About the Hotel</div>
            <div
              className="grayInfo"
              style={
                about
                  ? { height: "auto" }
                  : { height: "15rem", overflow: "hidden" }
              }
            >
              <HotelDescription descriptions={data.descriptions} />
            </div>
            {<ShowMore state={about} setState={setabout} />}
          </div>

          <div style={{ marginTop: "1rem" }}>
            <div className="subHeading">Location and Landmarks</div>

            <Landmarks nearByAttractions={data.nearByAttractions} number={4} />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <HotelLocation
              name={name}
              geoCode={data.geoCode}
              address={data.contact.address}
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <div className="subHeading" style={{ margin: 0 }}>
                Booking details
              </div>

              <Icon
                type={viewBookingSummary ? "minus" : "plus"}
                onClick={() => setviewBookingSummary(!viewBookingSummary)}
              />
            </div>

            {viewBookingSummary && (
              <>
                <div style={{ margin: "1rem 0" }}>
                  Details for{" "}
                  <b>
                    {props.location.state.noofroom}{" "}
                    {props.location.state.noofroom === 1 ? " room" : " rooms"},{" "}
                    {days} nights
                  </b>{" "}
                  and{" "}
                  <b>
                    {props.location.state.noofAdults} Adult
                    {props.location.state.noofAdults > 1 && "s"}{" "}
                    {props.location.state.noofChild > 0 &&
                      (props.location.state.noofChild === 1
                        ? "and 1 child"
                        : `and ${props.location.state.noofChild} children`)}
                  </b>
                  {props.childrenInfoString() && (
                    <div>{props.childrenInfoString()}</div>
                  )}
                </div>

                <div style={{ padding: "1rem", border: "1px solid #E9EDEF" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Check in:</div>
                    <div>
                      {moment(props.location.state.startDate)
                        .format("ddd")
                        .toString()}
                      , {props.location.state.startDate}
                    </div>
                    <div>{data.checkinInfo.beginTime}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Check out:</div>
                    <div>
                      {moment(props.location.state.endDate)
                        .format("ddd")
                        .toString()}
                      , {props.location.state.endDate}
                    </div>
                    <div>{data.checkoutInfo.time}</div>
                  </div>
                </div>
              </>
            )}
          </div>

          <RoomsAndRates
            hotelId={data.id}
            handleSelection={props.handleSelection}
            selected={props.selectedToken}
            setPaymentData={props.setpaymentData}
            location={props.location}
            openPolicyModal={props.openPolicyModal}
            setModal={props.setModal}
          />
        </div>
      </div>

      <FooterPage />
    </div>
  );
};
