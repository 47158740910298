import React from "react";
import { Menu } from "antd";
import "./Profile.css";
import Profile from "./Profile";
import Header from "../components/Header/Header";
import FooterPage from "../components/Footer";
import { AllBookings } from "./Booking/AllBookings";
class Profilenavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "profile",
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    this.setState({ current: e.key });
  }

  goBack = () => {
    this.props.history.goBack(); // Go back to the previous page
  };

  render() {
    return (
      <div>
        <Header />

        <div className="menuContainer">
          <a href="javascript:void(0)" style={{ color: '#f48148' }} onClick={this.goBack}>&lt; Back to Home</a>
        </div>

        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
          className="menuContainer"
        >

          <Menu.Item className="menuItem" key="profile">
            Profile
          </Menu.Item>
          <Menu.Item className="menuItem" key="booking">
            Booking
          </Menu.Item>
        </Menu>
        {this.state.current === "profile" ? <Profile /> : <AllBookings />}
        <FooterPage />
      </div>
    );
  }
}

export default Profilenavbar;
