import React from "react";
import "./LegalDocuments.css";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const TOS = () => {
  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  const TranslatedTOS = [
    {
      code: "one",
      content: ["one", "two", "three", "four"],
    },
    {
      code: "two",
      content: ["one", "two", "three", "four",],
    },
    {
      code: "three",
      content: [],
    },
    {
      code: "four",
      content: [],
    },
    {
      code: "five",
      content: [],
    },
    {
      code: "six",
      content: [],
    },
    {
      code: "seven",
      content: ["one", "two", "three", "four", "five", "six"],
    },
    {
      code: "eight",
      content: ["one", "two", "three", "four", "five", "six"],
    },
    {
      code: "nine",
      content: ["one", "two", "three",],
    },
    {
      code: "ten",
      content: ["one", "two",],
    },
    {
      code: "eleven",
      content: ["one", "two", "three"],
    },
    {
      code: "twelve",
      content: [],
    },
    {
      code: "thirteen",
      content: [],
    },
  ]
  const { t } = useTranslation()

  return (
    <div className="docsPage" style={{ height: "auto" }}>
      <Header isMobile={isMobile} isDarkFont={true} />
      <div className="docsContainer">
        <div className="docsHeading">{t('tos_title')}</div>

        <p className="docsPara">
          {TranslatedTOS.map((tos, index) => (
            <div key={index}>
              <div className="docsSubHeading">{++index}. {t(`tos_${tos.code}_title`)}</div>
              {
                tos.content.length == 0 ? (
                  <p className="docsParagraph">
                    {t(`tos_${tos.code}_content`)}
                  </p>
                ) : (
                  <ol className="docsOrderedListAlphabet">
                    {tos.content.map(c => (
                      <li>{t(`tos_${tos.code}_content_${c}`)}</li>
                    ))}
                  </ol>
                )
              }
            </div>
          ))}
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TOS;
