import React from "react";
import SearchBar from "../../SearchBar/SearchBar";

const HomeSearchBarBusiness = ({ hasProvider, setOpenLogin, history, openSignUp, }) => {
    return (
        <div className="HeroIndoSatCenter">
            <div className="HeroText" style={{
                color: 'white',
                height: 'auto',
                marginBottom: '8rem'
            }}>
                <div style={{ marginTop: '100px', fontWeight: 'bolder', fontSize: '5.8rem', color: 'rgb(217, 59, 79)' }}>
                    Book Your Rooms &
                </div>
                <div style={{ fontWeight: 'bolder', fontSize: '5.8rem', color: 'rgb(217, 59, 79)' }}>
                    Stay Connected With
                </div>
                <div style={{ fontWeight: 'bolder', fontSize: '5.8rem', color: 'rgb(217, 59, 79)' }}>
                    FREE Roaming
                </div>
            </div>
            <button className="btn-primary" style={{
                width: '100%',
                opacity: '100%',
                backgroundColor: '#d93b4f',
                outline: 'none',
                border: 'none',
                fontSize: '28px',
                width: '150px',
                height: '65px',
                borderRadius: '50px',
            }} onClick={openSignUp}>Sign Up</button>
            {/* <SearchBar hasProvider={hasProvider} showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="IndoSatHeroTextBottom" style={{ color: '#000000' }}>
          We have over 900,000 properties worldwide
        </div> */}
        </div>
    )
}

export default HomeSearchBarBusiness;
