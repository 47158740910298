import React, { Component } from "react";
import { Spin, Button } from "antd";
import Axios from "axios";
import "./RoomsAndRates.css";
import RoomPriceContainer from "./RoomPriceContainer";
import {_defaultHeaders, channelId, getCurrency, providerURL} from "../../AxiosHelper";
import {getStorage} from "../../LocalStorageHelpers";

export default class RoomsAndRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      loadMore: true,
      roomList: {},
      itemsDisplayed: -1,
    };
  }

  componentDidMount = () => {
    this.getRoomsAndRates();
  };

  getRoomsAndRates = async () => {

    const location = this.props.location ?? this.props.history.location
    let currency = getCurrency();
    let checkIn = location.state.startDate;
    let checkOut = location.state.endDate;;
    const occupy = [];
    const panes = location.state.panes[0];
    panes.forEach((item) => {
      occupy.push({
        numOfAdults : item.adults,
        childAges : item.childrenInfo
      })
    });
    let hotelId = this.props.hotelId;
    let token = getStorage("_search_result_token");

    const baseUrl = providerURL();
    let url = `${baseUrl}/hotel/${hotelId}/roomsandrates/${token}`;
    let data = {
      searchSpecificProviders: false,
    };

    if( !token ){
      url = `${baseUrl}/hotel/${hotelId}/roomsandrates`;
      data = {
        currency,
        checkIn,
        checkOut,
        occupancies : occupy,
        channelId: channelId(),
        culture: "en-US",
        countryOfResidence: "MY", // TODO : Need to get from user. If put MY get less result
        nationality: "MY", // TODO : Need to get from user. If put MY get less result
      }
    }

    const headers = _defaultHeaders(currency, this.props.location.state.correlationId)
    let response = await Axios.post(url,
      data,
      {
          headers
        }
    );
    if(!response.data.hotel ) {
      this.setState({roomLists: null, loading:false})
      return;
    }

    const roomLists = {};
    if (
      Object.keys(response.data.hotel).length > 0 &&
      response.data.hotel.rooms.length > 0
    ) {
      const res = response.data.hotel;
      const totalRoomType = res.standardizedRooms.length;
      for (let i = 0; i < totalRoomType; i++) {
        roomLists[res.standardizedRooms[i].id] = {
          currency: response.data.currency,
          roomDetails: res.standardizedRooms[i],
          room: [],
          rates: [],
          recomendation: [],
        };
      }

      res.recommendations.forEach((reco) => {
        // 1st rate
        const rate = reco.rates && reco.rates.length > 0 ? reco.rates : null;
        if( rate ){

          const oriRooms = [];
          const oriRatesAll = [];
          let oriRates = null;

          // const oriRooms = res.standardizedRooms.find((r) => r.id === reco.groupId);
          rate.forEach((recoRate) => {
            oriRatesAll.push(res.rates.find((r) => r.id === recoRate))
            oriRates = res.rates.find((r) => r.id === recoRate)

            oriRooms.push(res.rooms.find((r) => r.id === oriRates.occupancies[0].roomId))
          })

          // const groupRoomId = reco.groupId;
          const groupRoomId = oriRates.occupancies[0].stdRoomId;

          // console.log('oriRatesAll', oriRatesAll)
          // console.log('oriRates', oriRates)
          // console.log('oriRooms', oriRooms)

          if (roomLists[groupRoomId] ) {
            roomLists[groupRoomId].recomendation.push(reco);
            roomLists[groupRoomId].rates.push(oriRatesAll);
            roomLists[groupRoomId].room.push(oriRooms);
          }
        }
      });


      this.setState({
        ...this.state,
        roomLists,
        itemsDisplayed: Object.keys(roomLists).length,
      });
    }

    this.setState({
      loading: false,
      config: {},
      hotelId: response.data.hotel.id,
      token: response.data.token,
      currency: response.currency,
    });
  };

  loadMore = () => {
    this.setState({ loadMore: !this.state.loadMore });
  };

  itemFailedToLoad = () => {
    this.setState((state) => ({ itemsDisplayed: state.itemsDisplayed - 1 }));
  };


  render() {
    if (this.state.loading) {
      return (
        <div className="loading">
          <Spin size="large" />
        </div>
      );
    } else {
      if (
        !this.state.roomLists ||
        (!this.state.loading && this.state.itemsDisplayed <= 0)
      )
        return <div className="loading">No Rooms Available</div>;

      let combinationCosts = [];
      {
        Object.keys(this.state.roomLists).forEach((key) => {
          combinationCosts.push(
            <RoomPriceContainer
                key={key}
                location={this.props.location}
                hotelId={this.state.hotelId}
                token={this.state.token}
                config={this.state.config}
                roomList={this.state.roomLists[key]}
                handleSelection={this.props.handleSelection}
                selectedtoken={this.props.selected}
                setPaymentData={this.props.setPaymentData}
                itemFailedToLoad={this.itemFailedToLoad}
                openPolicyModal={this.props.openPolicyModal}
                setModal={this.props.setModal}

            />
          );
        });
      }

      return <div className="RoomsAndRatesContainer">{combinationCosts}</div>;
    }
  }
}
