import React from "react";
import SearchBar from "../../SearchBar/SearchBar";

const HomeSearchBarYoodo = ({hasProvider, setOpenLogin, history}) => {
  return (
      <div className="HeroIndoSatCenter">
        <div className="HeroText"  style={{
          color : 'white',
          height:'auto',
          marginBottom: '9rem'
        }}>
          <div style={{marginTop: 45,fontSize: '5rem', color: 'rgb(217, 59, 79)'}}>
            Free roaming with 
          </div>
          <div style={{fontSize: '5rem', color: 'rgb(217, 59, 79)'}}>
            every booking
          </div>
          <div style={{marginTop:20, fontSize:'2rem', width: '40vw', color: 'black'}}>
            Travel and enjoy FREE roaming passes in 72 countries with Yoodo!
          </div>
        </div>
        <SearchBar hasProvider={hasProvider} showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="IndoSatHeroTextBottom">
          We have over 900,000 properties worldwide
        </div>
      </div>
  )
}

export default HomeSearchBarYoodo;
