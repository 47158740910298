import { Button, Collapse, Input, Modal } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import tnc_img_icon from "../images/provider/indosat/tnc-icon.png";

export const BusinessDashboardNewsLetter = () => {
    const [showTncModal, setShowTncModal] = useState(false);


    const isMobile = useMediaQuery({
        maxWidth: 775
    })

    return (
        <div className="Home">
            <div className="IndosatPopularDestionationsContainer">
                <Modal
                    title="Terms & Conditions"
                    visible={showTncModal}
                    onOk={() => setShowTncModal(false)}
                    onCancel={() => setShowTncModal(false)}
                    footer={null}
                    style={{ top: 10 }}
                    className="tnc-modal"
                >
                    <Collapse>
                        <Collapse.Panel header="General Terms and Conditions" key="1">
                            <ul style={{ margin: '0 10px' }}>
                                <li>
                                    The rates listed include 10% VAT, valid for all prepaid and postpaid Indosat Ooredoo Hutchison customers.
                                </li>
                                <li>
                                    Benefits Unlimited Internet & Blackberry is valid for all operators in the destination country (subject to GPRS/3G/LTE service availability).
                                </li>
                                <li>
                                    The above roaming rates do not apply to maritime and satellite operator networks.
                                </li>
                                <li>
                                    Tariffs can change at any time without any prior notification from Indosat Ooredoo Hutchison.
                                </li>
                                <li>
                                    The availability and quality of roaming services (voice calls, SMS, internet, and BlackBerry) in the destination country depends on the coverage, compatibility, and network quality of Indosat Ooredoo Hutchison's roaming partner operator.
                                </li>
                            </ul>
                        </Collapse.Panel>
                        <Collapse.Panel header="Terms of Use Unlimited Roaming Package Benefits" key="2">
                            <ul style={{ margin: '0 10px' }}>
                                <li>
                                    Please ensure that the Data Roaming feature on your device is turned on. For example, on an Android device : Settings -{">"} More -{">"} Mobile Networks -{">"} Data roaming, change the option to Enable/ON to enable.
                                </li>
                                <li>
                                    The APN settings used on the customer's device are: indosatgprs.
                                </li>
                                <li>
                                    You do not need to manually select the operator in the roaming destination country.
                                </li>
                                <li>
                                    BlackBerry (BB) customers with versions below OS10 must have activated the BB package before using roaming services. The BB feature when roaming will follow the BB feature that customers register before traveling abroad. Example: If a customer while in Indonesia has registered for the BB Gaul package, when roaming, the registered package will automatically be carried over when the customer is roaming abroad.
                                </li>
                                <li>
                                    Roaming Data Control is a service that allows postpaid subscribers to automatically monitor data or Internet service usage while abroad. The customer will receive an SMS containing information on roaming data usage when it is still below the normal usage limit and data usage will be blocked when it is outside the normal usage limit. While telephone service & SMS can still be used.
                                </li>
                                <li>
                                    The package's active period and time zone refers to the time in Indonesia (WIB).
                                </li>
                            </ul>
                        </Collapse.Panel>
                    </Collapse>
                </Modal>
                <div className="tnc-row">
                    <div className="tnc-img-container" style={{ backgroundImage: `url( ${tnc_img_icon} )` }} />
                    <div className="tnc-title-container">
                        {isMobile ?
                            (
                                <div className="tnc-title" style={{ textAlign: 'center' }}>Free roaming terms & conditions</div>
                            ) :
                            (
                                <div className="tnc-title">
                                    Free roaming terms & conditions
                                </div>
                            )
                        }
                    </div>
                    <div className="tnc-open-modal-btn">
                        <button onClick={() => setShowTncModal(true)}>See Details</button>
                    </div>
                </div>
            </div>
        </div>

    );
};