import React, {useEffect, useState} from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import "./style.css"
import Header from "../../components/Header/Header";
import {Button, Spin} from "antd";
import { useMediaQuery } from "react-responsive";

const Loading = () => (
    <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
    >
      <Spin size="large" />
    </div>
);

const PaymentElementStripe = ({clientSecret, refId}) => {
  const isMobile = useMediaQuery({
    maxWidth : 775
  })

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //
  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }
  //
  //   setIsLoading(true);
  //
  //   const options = {
  //     elements,
  //     confirmParams: {
  //       // Make sure to change this to your payment completion page
  //       return_url: "http://localhost:3000",
  //     },
  //   }
  //
  //   console.log('Payment-Element-OPT', options)
  //
  //   const { error, ...etc } = await stripe.confirmPayment(options);
  //
  //   console.log('Payment-Element-ETC', etc)
  //
  //   // This point will only be reached if there is an immediate error when
  //   // confirming the payment. Otherwise, your customer will be redirected to
  //   // your `return_url`. For some payment methods like iDEAL, your customer will
  //   // be redirected to an intermediate site first to authorize the payment, then
  //   // redirected to the `return_url`.
  //   if (error.type === "card_error" || error.type === "validation_error") {
  //     setMessage(error.message);
  //   } else {
  //     setMessage("An unexpected error occured.");
  //   }
  //
  //   setIsLoading(false);
  // };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret = new URLSearchParams(window.location.search).get(
    //     "secret"
    // );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent, ...props }) => {
      if (paymentIntent.error) {
        alert('Failed' + JSON.stringify(paymentIntent.error));
        window.location.href = `/payment/booking?refId=${refId}&type=INPROGRESS`
        // Show error from server on payment form
      } else if (paymentIntent.status == "requires_action" ) {
        // Use Stripe.js to handle required card action
        stripe.confirmCardPayment(
            paymentIntent.client_secret
        ).then((res) => {
          if( res.paymentIntent ){
            window.location.href = `/payment/booking?refId=${refId}&type=INPROGRESS`
          }else{
            // show error
            alert('Failed' + JSON.stringify(res.error));
            window.location.href = `/payment/booking?refId=${refId}&type=INPROGRESS`
          }

        });
      }
      else {
        window.location.href = `/payment/booking?refId=${refId}&type=INPROGRESS`
      }

      // switch (paymentIntent.status) {
      //   case "succeeded":
      //     setMessage("Payment succeeded!");
      //     break;
      //   case "processing":
      //     setMessage("Your payment is processing.");
      //     break;
      //   case "requires_payment_method":
      //     setMessage("Your payment was not successful, please try again.");
      //     break;
      //   default:
      //     setMessage("Something went wrong.");
      //     break;
      // }
    });
  }, [stripe]);

  return (<>
      <Header isMobile={isMobile} isDarkFont={true} />
    <div className={"container"}>
      <Loading/>

      {/*<form id="payment-form" onSubmit={handleSubmit}>*/}
      {/*  /!*<PaymentElement id="payment-element" />*!/*/}
      {/*  <div style={{textAlign: 'center', width:'100%'}}>*/}
      {/*    <Button key="submit" type="primary" disabled={isLoading || !stripe || !elements}  style={{width:'200px'}}>*/}
      {/*      {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}*/}
      {/*    </Button>,*/}
      {/*  </div>*/}

      {/*  /!* Show any error or success messages *!/*/}
      {/*  {message && <div id="payment-message">{message}</div>}*/}
      {/*</form>*/}
    </div>
      </>
  )
}
export default PaymentElementStripe
